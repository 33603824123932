import New from "../../components/new/new";
import MoreDetails from "../../components/news-details-read-more/MoreDetails";
import { useEffect } from "react";
import { useParams } from 'react-router-dom';

const Details = () => {
  const { id } = useParams(); // Use id instead of slug

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-[#003366] md:min-h-lvh">
      <New id={id} /> {/* Pass id instead of slug */}
      <div className="p-6">
        <MoreDetails excludeSlug={id} /> {/* Pass id instead of slug */}
      </div>
    </div>
  );
};

export default Details;

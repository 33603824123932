import React from "react";
import go_back from "../../assets/logo/Forward_Button.svg";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import noData from '../../assets/images/nodata.png'; 
import 'react-loading-skeleton/dist/skeleton.css';

const linksData = Array.from({ length: 5 }, (_, i) => ({
    id: i + 1,
    question: <Skeleton />,
    answers: Array.from({ length: 3 }, (_, j) => ({
        text: <Skeleton />,
        percentage: <Skeleton />,
        count: <Skeleton />,
        isCorrect: Math.random() < 0.5,
    })),
    correctAnswer: <Skeleton />,
    time: <Skeleton />,
    date: <Skeleton />,
}));

function QA() {
    return (
        <div className="flex flex-col justify-start items-center bg-bg">
            <div className="flex flex-col justify-evenly items-center w-full p-4 text-center text-white font-montserrat-semibold">
                <div className="flex flex-row justify-between items-center w-full">
                    <div></div>
                    <div className="flex flex-row justify-between items-center w-full">
                        <div></div>
                        <div className="tab ">
                            <ul>
                                <li className="list-item active  ">
                                    <b className="left-curve"></b>
                                    <b className="right-curve"></b>
                                    <h2 className="text-hoverText px-4 py-1"> أسئلة وأجوبة</h2>
                                </li>
                            </ul>
                        </div>

                        <div className="absolute right-5 my-4">
                            <Link to="/">
                                <img src={go_back} alt="" />
                            </Link>
                        </div>
                    </div>
                </div>

                <div style={{ direction: 'rtl' }} className="bg-white w-full rounded-2xl border-4 border-white py-16 px-6 text-right space-y-14">
                    {linksData.length > 0 ? (
                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 justify-center">
                            {linksData.map((link) => (
                                <div
                                    className="bg-qa shadow-md hover:transform hover:scale-105 transition-transform duration-300 pr-2"
                                    key={link.id}
                                >
                                    <div>
                                        <h1 className="text-text-blueNav p-2 font-montserrat-semibold">
                                            {link.question}
                                        </h1>
                                    </div>
                                    <div className="2xl:grid-cols-3 gap-y-14 sm:grid-cols-1 lg:grid-cols-2 flex justify-start items-start my-4 text-center">
                                        {link.answers.map((answer, index) => (
                                            <div
                                                key={index}
                                                className={`${answer.isCorrect ? "bg-bxCorrect" : "bg-bx"} rounded-md py-2 px-4 ml-2 w-fit`}
                                            >
                                                <h2 className={`${answer.isCorrect ? "text-white" : "text-black"} font-montserrat-medium`}>
                                                    {answer.text}
                                                </h2>
                                                <div className="flex flex-row-reverse justify-evenly items-start">
                                                    <p className={`${answer.isCorrect ? "text-white" : "text-black"} text-sm font-montserrat-medium`}>
                                                        ({answer.count})
                                                    </p>
                                                    <p className={`${answer.isCorrect ? "text-white" : "text-text-blueNav"} text-sm font-bolder font-montserrat-semibold`}>
                                                        {answer.percentage}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="flex flex-row justify-start items-center text-black text-xs font-thin space-x-1">
                                        <p>{link.correctAnswer}</p>
                                        <h3>:الإجابة الصحيحة</h3>
                                    </div>

                                    <div className="text-[#808080] flex justify-start items-center gap-4 font-montserrat-regular my-2 text-xs">
                                        <div>
                                            <h4>{link.time}</h4>
                                        </div>
                                        <div>
                                            <h3>{link.date}</h3>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="flex flex-col justify-center items-center w-full h-full">
                            <img src={noData} alt="No data" className="max-w-full max-h-full" />
                            <p className="text-center text-xl font-montserrat-bold text-[#003366] mt-4">لا توجد بيانات</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default QA;

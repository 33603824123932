import React, { useEffect } from 'react';
import SearchExamComponent from '../../components/searchexam/SearchExamComponent'

function SearchExam() {

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);


  return (
    <div className="bg-[#003366] px-2 pb-2 lg:px-14 min-h-[60vh] lg:min-h-[90vh] pt-2">
      <SearchExamComponent/>
    </div>
  )
}

export default SearchExam

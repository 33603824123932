import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FaFileDownload } from 'react-icons/fa';
import { FcSearch } from "react-icons/fc";
import { AiOutlineLoading3Quarters } from 'react-icons/ai'; // Import the loader icon
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Swal from 'sweetalert2';

function SearchExamComponent() {
  const location = useLocation();
  const { id, type } = location.state || {};

  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [levels, setLevels] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState('');
  const [majors, setMajors] = useState([]);
  const [selectedMajor, setSelectedMajor] = useState('');
  const [rounds, setRounds] = useState([]);
  const [selectedRound, setSelectedRound] = useState('');
  const [loadingYears, setLoadingYears] = useState(true);
  const [loadingLevels, setLoadingLevels] = useState(false);
  const [loadingMajors, setLoadingMajors] = useState(false);
  const [loadingRounds, setLoadingRounds] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [downloadLink, setDownloadLink] = useState('');
  const [error, setError] = useState('');
  const [showNoData, setShowNoData] = useState(true); // State to manage showing/hiding noData image
  const [formSubmitted, setFormSubmitted] = useState(false); // State to track form submission

  useEffect(() => {
    const fetchYears = async () => {
      try {
        setLoadingYears(true);
        const response = await fetch(`https://admin.aliwehbedu.com/v1_0_0-main/get-related-section-data?id=${id}&type=${type}`);
        const data = await response.json();
        if (data.success) {
          setYears(data.years || []);
        } else {
          console.error('Failed to fetch years data:', data.error);
        }
      } catch (error) {
        console.error('Error fetching years data:', error);
      } finally {
        setLoadingYears(false);
      }
    };

    if (id && type) {
      fetchYears();
    }
  }, [id, type]);

  const fetchLevels = async (yearId) => {
    try {
      setLoadingLevels(true);
      const response = await fetch(`https://admin.aliwehbedu.com/v1_0_0-main/get-levels?year_id=${yearId}`);
      const data = await response.json();
      if (data.success) {
        setLevels(data.data || []);
      } else {
        console.error('Failed to fetch levels data:', data.error);
      }
    } catch (error) {
      console.error('Error fetching levels data:', error);
    } finally {
      setLoadingLevels(false);
    }
  };

  const fetchMajors = async (yearId, levelId) => {
    try {
      setLoadingMajors(true);
      const response = await fetch(`https://admin.aliwehbedu.com/v1_0_0-main/get-majors?year_id=${yearId}&level_id=${levelId}`);
      const data = await response.json();
      if (data.success) {
        setMajors(data.data || []);
      } else {
        console.error('Failed to fetch majors data:', data.error);
      }
    } catch (error) {
      console.error('Error fetching majors data:', error);
    } finally {
      setLoadingMajors(false);
    }
  };

  const fetchRounds = async () => {
    try {
      setLoadingRounds(true);
      const response = await fetch(`https://admin.aliwehbedu.com/v1_0_0-main/get-related-section-data?id=${id}&type=${type}`);
      const data = await response.json();
      if (data.success) {
        setRounds(data.rounds || []);
      } else {
        console.error('Failed to fetch rounds data:', data.error);
      }
    } catch (error) {
      console.error('Error fetching rounds data:', error);
    } finally {
      setLoadingRounds(false);
    }
  };

  const handleYearChange = (event) => {
    const selectedYearId = event.target.value;
    setSelectedYear(selectedYearId);
    fetchLevels(selectedYearId);
    setSelectedLevel('');
    setMajors([]);
    setRounds([]);
  };

  const handleLevelChange = (event) => {
    const selectedLevelId = event.target.value;
    setSelectedLevel(selectedLevelId);
    fetchMajors(selectedYear, selectedLevelId);
    setSelectedMajor('');
    setRounds([]);
  };

  const handleMajorChange = (event) => {
    const selectedMajorId = event.target.value;
    setSelectedMajor(selectedMajorId);
    fetchRounds();
    setSelectedRound('');
  };

  const handleRoundChange = (event) => {
    const selectedRoundId = event.target.value;
    setSelectedRound(selectedRoundId);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (!selectedYear || !selectedLevel || !selectedMajor || !selectedRound) {
      setError('يرجى ملء جميع الحقول بالترتيب');
      return;
    }
    
    setError('');
    setLoadingSubmit(true);
    setFormSubmitted(true);

    try {
      const response = await fetch(`https://admin.aliwehbedu.com/v1_0_0-main/get-record?year_id=${selectedYear}&level_id=${selectedLevel}&major_id=${selectedMajor}&round_id=${selectedRound}`);
      const data = await response.json();
      if (data.success) {
        setDownloadLink(data.data.path);
        setShowNoData(false); // Hide noData image
      } else {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: 'عذرًا، لا يتوفر الملف حاليًا',
          confirmButtonText: 'موافق'
        }).then(() => {
          // Reload the page after user clicks OK on the error alert
          window.location.reload();
        });
      }
    } catch (error) {
      console.error('Error fetching download link:', error);
      Swal.fire({
        icon: 'error',
        title: 'خطأ',
        text: 'عذرًا، حدث خطأ أثناء جلب رابط التحميل',
        confirmButtonText: 'موافق'
      }).then(() => {
        // Reload the page after user clicks OK on the error alert
        window.location.reload();
      });
    } finally {
      setLoadingSubmit(false);
    }
  };

  const renderRoundLabel = (roundId) => {
    switch (roundId) {
      case 7:
        return 'الدورة العادية';
      case 8:
        return 'الدورة الاستثنائية';
      default:
        return '';
    }
  };

  return (
    <div className="bg-white  min-h-[90vh] lg:min-h-[90vh] flex flex-col justify-center items-center rounded-lg shadow-lg p-6 my-8 font-montserrat-semibold" style={{ direction: 'rtl' }}>
      <div className="flex  flex-col lg:flex-row">
        <div className="w-full pr-4">
          <div className="text-right mb-6">
            <h2 className="lg:text-3xl text-2xl font-bold text-[#003366]">دورات الإمتحانات المهنية</h2>
          </div>
          <form className="space-y-4 w-[310px] md:w-[650px] lg:w-[700px]" onSubmit={handleFormSubmit}>
            <div className="flex flex-col">
              <label htmlFor="academicYear" className="text-lg text-[#003366] mb-2">العام الدراسي:</label>
              <select
                id="academicYear"
                className="border border-gray-300 rounded-md py-3 px-3 focus:outline-none focus:border-blue-500 text-right"
                onChange={handleYearChange}
                value={selectedYear}
              >
                <option value="" disabled>اختر العام الدراسي</option>
                {loadingYears ? (
                  <option>Loading years...</option>
                ) : (
                  years.map(year => (
                    <option key={year.id} value={year.id}>{year.year}</option>
                  ))
                )}
              </select>
            </div>
            <div className="flex flex-col">
              <label htmlFor="courses" className="text-lg text-[#003366] mb-2">المستوى:</label>
              <select
                id="courses"
                className="border border-gray-300 rounded-md py-3 px-3 focus:outline-none focus:border-blue-500 text-right"
                onChange={handleLevelChange}
                value={selectedLevel}
              >
                <option value="" disabled>اختر المستوى</option>
                {loadingLevels ? (
                  <option>Loading levels...</option>
                ) : (
                  levels.map(level => (
                    <option key={level.id} value={level.id}>{level.level}</option>
                  ))
                )}
              </select>
            </div>
           <div className="flex flex-col">
            <label htmlFor="specialization" className="text-lg text-[#003366] mb-2">الإختصاص:</label>
            <select
              id="specialization"
              className="border border-gray-300 rounded-md py-3 px-3 focus:outline-none focus:border-blue-500 text-right"
              onChange={handleMajorChange}
              value={selectedMajor}
            >
              <option value="" disabled>اختر التخصص</option>
              {loadingMajors ? (
                <option>Loading majors...</option>
              ) : (
                majors.map(major => (
                  <option key={major.id} value={major.id}>{major.major}</option>
                ))
              )}
            </select>
          </div>
          <div className="flex flex-col">
            <label htmlFor="stage" className="text-lg text-[#003366] mb-2">الدورة:</label>
            <select
              id="stage"
              className="border border-gray-300 rounded-md py-3 px-3 focus:outline-none focus:border-blue-500 text-right"
              onChange={handleRoundChange}
              value={selectedRound}
            >
              <option value="" disabled>اختر الدورة</option>
              {loadingRounds ? (
                <option>Loading rounds...</option>
              ) : (
                rounds.map(round => (
                  <option key={round.id} value={round.id}>{renderRoundLabel(round.id)}</option>
                ))
              )}
            </select>
          </div>
          {error && (
            <div className="text-red-500 text-sm">{error}</div>
          )}
          <button type="submit" className="bg-[#003366] text-white py-3 px-4 rounded-md hover:bg-blue-600 transition-colors duration-300 w-full relative">
  {loadingSubmit ? (
    <span className=" transform  flex items-center justify-center  space-x-2">
      <AiOutlineLoading3Quarters className="animate-spin text-white" />
      <span className="text-md justify-center text-center pr-2">البحث جاري</span>
    </span>
  ) : (
    "بحث"
  )}
</button>

        </form>
        
      </div><div className="flex-col items-center justify-center lg:pr-12 flex mt-8 lg:mt-0 lg:ml-4 w-full lg:w-full">


        {!formSubmitted ? (
          <>
            <FcSearch size={300} className="text-[#003366]" />
            <p className="text-[#003366] mt-2">ابحث عن دورتك المطلوبة</p>
          </>
        ) : (
          <>
            <FaFileDownload size={250} className="text-[#003366]" />
            <p className="text-[#003366] my-4 w-68 lg:w-96 mx-4">بعد نقر الرابط سيتم تحميل الملف الى الكمبيوتر</p>
            {downloadLink && (
              <a href={downloadLink} onClick={() => {
                Swal.fire({
                  icon: 'success',
                  title: 'تم التحميل بنجاح',
                  text: 'تم تحميل الملف بنجاح إلى جهازك',
                  confirmButtonText: 'موافق'
                }).then(() => {
                  window.location.reload();
                });
              }} target="_blank" rel="noopener noreferrer" className="text-blue-500 w-96 text-center underline">تحميل ملف Zip</a>
            )}
          </>
        )}
      </div>
    </div>
  </div>
  );
}

export default SearchExamComponent;


import React from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Home from "./pages/home/Home";
import LiveNews from "./pages/live-news/LiveNews";
import News from "./pages/news/News";
import MyDiary from "./pages/my-diary/MyDiary";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import Details from "./pages/newsDetails/Details";
import UsefulLinks from "./components/useful-links/UsefulLinks";
import Paperwork from "./components/paper-work/Paperwork";
import NeededPapersDetails from "./components/needed-papers-details/NeededPapersDetails";
import Bio from "./components/bio/Bio";
import Materials from "./components/materials/Materials";
import ExamsYear from "./components/exams-year/ExamsYear";
import QA from "./components/q-a/QA";
import History from "./pages/voting-history/History";
import ExamPDF from "./components/exam-pdf/ExamPDF";
import NotFound from "./pages/not-found/NotFound";
import NotesDetails from "./components/notes/NotesDetails";
import SectionDetails from "./components/section-details/SectionDetails";
import NavbarFooter from './components/footer/NavbarFooter';
import SearchPage from './components/search/SearchPage';
import SearchResult from './components/search/SearchResult';
import SearchExam from './pages/search-exam/SearchExam';

function App() {
  return (
    <Router>
      <div className="app-container">
        <main className="content">
          <Routes>
            <Route path="/" element={<HeaderAndContentWithFooter><Home /></HeaderAndContentWithFooter>} />
            <Route path="/live-news" element={<HeaderAndContentWithFooter><LiveNews /></HeaderAndContentWithFooter>} />
            <Route path="/news" element={<HeaderAndContentWithFooter><News /></HeaderAndContentWithFooter>} />
            <Route path="/useful-links" element={<HeaderAndContentWithFooter><UsefulLinks /></HeaderAndContentWithFooter>} />
            <Route path="/paperwork/:id/:type" element={<HeaderAndContentWithFooter><Paperwork /></HeaderAndContentWithFooter>} />
            <Route path="/needed-papers-details/:id" element={<HeaderAndContentWithFooter><NeededPapersDetails /></HeaderAndContentWithFooter>} />
            <Route path="/my-diary" element={<HeaderAndContentWithFooter><MyDiary /></HeaderAndContentWithFooter>} />
            <Route path="/bio" element={<HeaderAndContentWithFooter><Bio /></HeaderAndContentWithFooter>} />
            <Route path="/qa" element={<HeaderAndContentWithFooter><QA /></HeaderAndContentWithFooter>} />
            <Route path="/exams-year/:slug" element={<HeaderAndContentWithFooter><ExamsYear /></HeaderAndContentWithFooter>} />
            <Route path="/exam-pdf/:slug" element={<ExamPDFWithNoHeaderAndFooter />} />
            <Route path="/search" element={<HeaderAndContentWithFooter><SearchPage /></HeaderAndContentWithFooter>} />
            <Route path="/materials/:slug" element={<HeaderAndContentWithFooter><Materials /></HeaderAndContentWithFooter>} />
            <Route path="/about" element={<HeaderAndContentWithFooter><About /></HeaderAndContentWithFooter>} />
            <Route path="/contact" element={<HeaderAndContentWithFooter><Contact /></HeaderAndContentWithFooter>} />
            <Route path="/result" element={<HeaderAndContentWithFooter><SearchResult /></HeaderAndContentWithFooter>} />
            <Route path="/news-details/:id" element={<HeaderAndContentWithFooter><Details /></HeaderAndContentWithFooter>} />
            <Route path="/notes-Details" element={<HeaderAndContentWithFooter><NotesDetails /></HeaderAndContentWithFooter>} />
            <Route path="/voting-history/:sectionid/:useruuid/:offset" element={<HeaderAndContentWithFooter><History /></HeaderAndContentWithFooter>} />
            <Route path="/section-details/:slug" element={<HeaderAndContentWithFooter><SectionDetails /></HeaderAndContentWithFooter>} />
            <Route path="/search-exams/:id/:type" element={<HeaderAndContentWithFooter><SearchExam /></HeaderAndContentWithFooter>} />
            <Route path="*" element={<HeaderAndContentWithFooter><NotFound /></HeaderAndContentWithFooter>} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

function HeaderAndContentWithFooter({ children }) {
  return (
    <>
      <Header />
      {children}
      <NavbarFooter />
      <Footer />
    </>
  );
}

function ExamPDFWithNoHeaderAndFooter() {
  let { slug } = useParams();
  return <ExamPDF slug={slug} />;
}

export default App;

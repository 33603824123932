import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'; // Import the skeleton CSS
import noData from "../../assets/images/nodata.png";

// Global variable to store cached notes
let cachedNotes = [];

function Notes() {
    const [notes, setNotes] = useState(cachedNotes);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (cachedNotes.length === 0) {
            fetchNotes();
        }
    }, []);

    const fetchNotes = async () => {
        setLoading(true);
        try {
            const response = await axios.get("https://admin.aliwehbedu.com/v1_0_0-main/get-memories");
            setNotes(response.data.data);
            cachedNotes = response.data.data; 
        } catch (error) {
            console.error("Error fetching notes:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleNoteClick = (note) => {
        navigate('/notes-details', { state: { note } }); 
    };

    return (
        <div className="container mx-auto px-4 py-12" style={{ direction: "rtl" }}>
            <div className="flex justify-center">
                {loading ? (
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 lg:gap-16 md:gap-16">
                        {[...Array(8).keys()].map((index) => (
                            <div key={index} className="relative w-42 lg:w-64 md:w-56 md:h-48 w-36 h-36 bg-yellow-200 p-4 shadow-lg">
                                <Skeleton width="100%" height="100%" className="rounded-md w-full h-full" />
                            </div>
                        ))}
                    </div>
                ) : notes.length > 0 ? (
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 lg:gap-16 md:gap-16">
                        {notes.map((note) => (
                            <div key={note.id} className="relative w-42 lg:w-64 md:w-56 h-48 bg-yellow-200 p-4 shadow-lg cursor-pointer" onClick={() => handleNoteClick(note)}>
                                <Link to={{ pathname: "/notes-details", state: { note } }}>
                                    <div className="absolute w-20 h-4 bg-blueborder opacity-40 top-0 left-1/2 transform -translate-x-1/2 -translate-y-2 rotate-2"></div>
                                    <div className="text-center font-montserrat-semibold text-sm lg:text-lg md:text-lg items-center">{note.title}</div>
                                </Link>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="flex flex-col justify-center items-center w-full h-full">
                    <img src={noData} alt="No data" className="max-w-full max-h-full" />
                    <p className="text-center text-xl font-montserrat-bold text-[#003366] mt-4">لا توجد بيانات</p>
                  </div>
                )}
            </div>
        </div>
    );
}

export default Notes;
import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import SocialMediaLinks from '../social-media/Social';
import { IoIosCloseCircle } from 'react-icons/io';
import { useParams } from 'react-router-dom';

const New = () => {
  const { id } = useParams(); 

  const [hoverMessage, setHoverMessage] = useState('');
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [mediaSrc, setMediaSrc] = useState('');
  const [newsData, setNewsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0); 

  useEffect(() => {
    fetchNewsData();
  }, [id]); 

  const fetchNewsData = async () => {
    try {
      let foundNewsItem = null;
      let currentOffset = 0;

     
      while (!foundNewsItem && currentOffset < 100) { 
        const response = await fetch(`https://admin.aliwehbedu.com/v1_0_0-main/get-news?offset=${currentOffset}`);
        const data = await response.json();
        console.log('API Response:', data); 

        if (data && data.success && Array.isArray(data.data)) {
          console.log('Fetched data array:', data.data); 
          const newsItem = data.data.find((item) => item.id === parseInt(id));
          if (newsItem) {
            console.log('Found news item:', newsItem); 
            foundNewsItem = newsItem;
          } else {
            console.log(`News with id "${id}" not found in current offset range.`);
          }
        } else {
          console.log('Invalid data format or no data available.');
        }

        currentOffset += 10; 
      }

      if (foundNewsItem) {
        setNewsData(foundNewsItem);
      } else {
        console.log(`News with id "${id}" not found.`);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleMouseEnter = (event) => {
    setHoverMessage('View image');
    setMousePosition({ x: event.clientX, y: event.clientY });
  };

  const handleMouseMove = (event) => {
    setMousePosition({ x: event.clientX, y: event.clientY });
  };

  const handleMouseLeave = () => {
    setHoverMessage('');
  };

  const handleImageClick = (imageSrc) => {
    setMediaSrc(imageSrc);
    setIsImageModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsImageModalOpen(false);
  };

  if (loading) {
    return (
      <div className="flex justify-center relative">
        <div className="bg-white border-solid border-2 border-white rounded-lg w-5/6 p-8 text-center mt-28 mb-8 relative">
          <Skeleton height={200} width={200} className="mx-auto mb-8 -mt-32 rounded-xl cursor-pointer" />
          <Skeleton height={20} width={200} className="mb-6 mx-auto" />
          <Skeleton count={5} className="text-blueborder font-montserrat-semibold text-sm text-right" />
        </div>
      </div>
    );
  }

  if (!newsData) {
    return (
      <div className="flex justify-center relative">
        <div className="bg-white border-solid border-2 border-white rounded-lg w-5/6 p-8 text-center mt-28 mb-8 relative">
          <p className="text-xl text-red-600 font-montserrat-bold mb-6">لا توجد بيانات</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-center relative">
      <div className="bg-white border-solid border-2 border-white rounded-lg w-full lg:w-5/6 md:w-5/6 p-8 text-center mt-28 mb-8 relative flex flex-col justify-center">
        <img
          className="mx-auto -mt-32 rounded-xl cursor-pointer"
          width="400px"
          height="400px"
          src={`https://admin.aliwehbedu.com${newsData.main_image}`}
          alt="New Image"
          onMouseEnter={handleMouseEnter}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleImageClick(`https://admin.aliwehbedu.com${newsData.main_image}`)}
        />
        {hoverMessage && (
          <div
            className="absolute text-white bg-gray-800 lg:px-2 md:px-2 py-1 rounded"
            style={{ top: mousePosition.y - 160, left: mousePosition.x - 100 }}
          >
            {hoverMessage}
          </div>
        )}
        <div className="social-media-container flex justify-center mb-4">
          <SocialMediaLinks />
        </div>
        <h1 className="text-xl text-red-600 font-montserrat-bold mb-6" style={{direction: 'rtl' }}>
          {newsData.title}
        </h1>
        <div
          className="text-blueborder font-montserrat-semibold text-xs md:text-sm lg:text-sm text-right"
          style={{
            lineHeight: '2.5',
            width: '100%',
            maxWidth: '100%',
            overflowWrap: 'break-word',
            direction: 'rtl',
          }}
          dangerouslySetInnerHTML={{ __html: newsData.content }}
        />
        <p className="text-left text-gray-400 text-xs md:text-sm font-montserrat-semibold">
          {newsData.created_at}
        </p>
      </div>
      {isImageModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-40 mt-10">
          <div className="relative md:w-1/2 w-3/4 bg-white p-4 rounded-lg">
            <button
              className="absolute top-1 right-2 text-red-500"
              onClick={handleCloseModal}
            >
              <IoIosCloseCircle className="w-8 h-8" />
            </button>
            <img
              src={mediaSrc}
              alt="Large View"
              className="w-full mt-8 h-auto max-h-screen max-w-screen"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default New;

import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

let cachedExamData = null;

function Countdown() {
  const [mainExam, setMainExam] = useState({
    title: "",
    days: "",
    hours: "",
    minutes: "",
    seconds: "",
  });
  const [otherExams, setOtherExams] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let interval;

    const fetchExamData = async () => {
      try {
        if (cachedExamData) {
          setMainExam(cachedExamData.mainExam);
          setOtherExams(cachedExamData.otherExams);
          setLoading(false);
        } else {
          const response = await fetch(
            "https://admin.aliwehbedu.com/v1_0_0-main/get-sections"
          );
          const data = await response.json();
          const officialExams = data.official_exams;

          const mainExamData = officialExams.find(
            (exam) => exam.main_option === 1
          );
          if (mainExamData) {
            const mainExamDate = new Date(mainExamData.start_date);
            setMainExam((prev) => ({ ...prev, title: mainExamData.title }));
            updateMainCountdown(mainExamDate);
            interval = setInterval(
              () => updateMainCountdown(mainExamDate),
              1000
            );
          }

          const otherExamData = officialExams
            .filter((exam) => exam.main_option === 0)
            .map((exam) => {
              const examDate = new Date(exam.start_date);
              const now = new Date();
              const timeDifference = examDate - now;
              const days =
                timeDifference > 0
                  ? Math.floor(timeDifference / (1000 * 60 * 60 * 24))
                  : 0;
              return { title: exam.title, days };
            });

          setOtherExams(otherExamData);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching exam data:", error);
        setLoading(true);
      }
    };

    const updateMainCountdown = (examDate) => {
      const now = new Date();
      const timeDifference = examDate - now;

      if (timeDifference > 0) {
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        setMainExam((prev) => ({ ...prev, days, hours, minutes, seconds }));
      } else {
        setMainExam((prev) => ({
          ...prev,
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        }));
      }
    };

    fetchExamData();

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  if (loading || !mainExam.title) {
    return null;
  }

  return (
    <div className="count_down">
      <div className="flex justify-center mx-auto flex-wrap flex-col">
        <div className="bg-white rounded-2xl flex justify-center items-center flex-col mx-auto px-3 my-3 lg:w-[600px] lg:h-[250px] w-auto h-auto lg:p-0 p-1">
          <div className="text-md md:text-xl lg:text-3xl text-[#003366] font-montserrat-semibold mb-4 text-center">
            {loading ? <Skeleton width={300} height={30} /> : mainExam.title}
          </div>
          <div className="flex flex-row gap-2 ">
            {["days", "hours", "minutes", "seconds"].map((unit, index) => (
              <div
                key={index}
                className="m-1 rounded-xl flex md:w-28 flex-col items-center justify-center lg:w-28 py-2 md:py-0"
                style={{
                  background: "linear-gradient(to bottom, #003366, #B20509)",
                  minWidth: "60px",
                }}
              >
                <div className="text-white text-[20px] md:text-[50px] font-bold">
                  {loading ? (
                    <Skeleton width={40} height={40} />
                  ) : (
                    mainExam[unit]
                  )}
                </div>
                <div className="text-white font-montserrat-regular text-[12px] md:text-[25px]">
                  {unit.charAt(0).toUpperCase() + unit.slice(1)}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-wrap justify-center items-center lg:w-[60%] lg:mx-auto">
          {loading
            ? [...Array(3)].map((_, index) => (
                <div
                  key={index}
                  className="bg-white rounded-2xl flex flex-wrap justify-center items-center flex-row  my-3 p-2"
                >
                  <div className="flex flex-col items-center mb-4">
                    <div className="text-[18px] h-2 md:text-[25px] text-[#003366] font-montserrat-semibold  text-center">
                      <Skeleton width={100} height={10} />
                    </div>
                    <div
                      className="rounded-xl flex flex-col items-center justify-center lg:w-28  py-12 md:py-0 "
                      style={{
                        background:
                          "linear-gradient(to bottom, #003366, #B20509)",
                        minWidth: "80px",
                      }}
                    >
                      <div className="text-white h-1 text-[20px] md:text-[50px] font-bold ">
                        <Skeleton width={40} height={10} />
                      </div>
                      <div className="text-white font-montserrat-regular text-[12px] md:text-[25px]">
                        Days
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : otherExams.map((exam, index) => (
                <div
                  key={index}
                  className="bg-white rounded-2xl flex flex-wrap justify-center items-center flex-row mx-auto my-3 lg:w-[160px] h-auto md:w-[180px] w-[130px] p-2"
                >
                  <div className="flex flex-col items-center mb-4">
                    <div className="text-md md:text-xl lg:text-3xl text-[#003366] font-montserrat-semibold mb-2 text-center">
                      {exam.title}
                    </div>
                    <div
                      className="rounded-xl flex  h-[75px] md:h-auto flex-col items-center justify-center lg:w-28 md:w-28  py-2 md:py-0"
                      style={{
                        background:
                          "linear-gradient(to bottom, #003366, #B20509)",
                        minWidth: "80px",
                      }}
                    >
                      <div className="text-white text-[20px] md:text-[50px] font-bold">
                        {exam.days}
                      </div>
                      <div className="text-white font-montserrat-regular text-[12px] md:text-[25px]">
                        Days
                      </div>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
}

export default Countdown;

import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaCircleArrowRight } from "react-icons/fa6";
import noData from '../../assets/images/nodata.png';
import materialsImage from "../../assets/images/materials.png";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// Global variable to store cached exam years
let cachedExamYears = {};

function ExamsYear() {
    const location = useLocation();
    const materialId = location.state?.materialId;
    const [examYears, setExamYears] = useState(cachedExamYears[materialId] || []);
    const [materialName, setMaterialName] = useState('');
    const [loading, setLoading] = useState(!cachedExamYears[materialId]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (location.state?.materialName) {
            setMaterialName(location.state.materialName);
        }
    }, [location.state?.materialName]);

    useEffect(() => {
        const fetchExamYears = async () => {
            try {
                if (cachedExamYears[materialId]) {
                    // Use cached exam years if available
                    setExamYears(cachedExamYears[materialId]);
                } else {
                    const response = await fetch(`https://admin.aliwehbedu.com/v1_0_0-main/get-grade-courses-years/?id=${materialId}`);
                    if (response.ok) {
                        const data = await response.json();
                        if (data.success) {
                            setExamYears(data.data);
                            cachedExamYears[materialId] = data.data;
                        } else {
                            setError("Failed to load exam years");
                        }
                    }
                }
            } catch (error) {
                setError("An error occurred while fetching exam years");
            } finally {
                setLoading(false);
            }
        };

        fetchExamYears();
    }, [materialId]);

    const handleBackClick = () => {
        navigate(-1);
    };

    return (
        <div className="flex flex-col justify-start items-center bg-bg md:min-h-lvh">
            <div className="flex flex-col justify-evenly items-center w-full p-4 text-center text-white font-montserrat-semibold">
                <div className="flex flex-row justify-between items-center w-full">
                    <div className="tab">
                        <ul>
                            <li className="list-item active">
                                <b className="left-curve"></b>
                                <b className="right-curve"></b>
                                <h2 className="text-hoverText">{materialName || <Skeleton />}</h2>
                            </li>
                        </ul>
                    </div>
                    <button onClick={handleBackClick} className="text-white absolute right-2 md:right-5 my-4 rounded-full focus:outline-none text-right">
                        <FaCircleArrowRight className="text-4xl" />
                    </button>
                </div>
                <div className="bg-white img-class w-full rounded-2xl p-4 text-right space-y-14">
                    {loading ? (
                        <div className='grid gap-x-8 gap-y-10 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-center my-3' style={{ direction: 'rtl' }}>
                            {[...Array(8)].map((_, index) => (
                                <div key={index} className='relative w-full h-24 md:h-32 lg:h-30 hover:transform hover:scale-105 transition-transform duration-300'>
                                    <Skeleton className='w-full h-full rounded' />
                                </div>
                            ))}
                        </div>
                    ) : error ? (
                        <p className="text-red-500">{error}</p>
                    ) : (
                        <>
                            {examYears.length > 0 ? (
                                <div className='grid gap-x-8 gap-y-10 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-center my-3' style={{ direction: 'rtl' }}>
                                    {examYears.map((year, index) => (
                                        <div key={index} className='relative w-full h-24 md:h-32 lg:h-30 hover:transform hover:scale-105 transition-transform duration-300'>
                                            <img
                                                src={`https://admin.aliwehbedu.com${year.main_image || materialsImage}`}
                                                className='w-full h-full rounded object-cover'
                                                alt=""
                                                onError={(e) => (e.target.src = materialsImage)}
                                            />
                                            <div className="absolute inset-0 bg-black opacity-60 rounded"></div>
                                            <h1 className='absolute inset-0 flex justify-center items-center text-center text-white text-2xl'>
                                                {year.year}
                                                <br />
                                                S : {year.round}
                                            </h1>
                                            <Link to={`/exam-pdf/${year.slug}`} state={{ pdfPath: year.attachments[0]?.file_path, year: year.year, round: year.round }}>
                                                <button className='absolute inset-0 w-full h-full bg-transparent cursor-pointer' />
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="flex flex-col justify-center items-center w-full h-full">
                                    <img src={noData} alt="No data" className="max-w-full max-h-full" />
                                    <p className="text-center text-xl font-montserrat-bold text-[#003366] mt-4">لا توجد بيانات</p>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ExamsYear;

import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import "./navbar.css";
import home_logo from "../../assets/logo/home-logo.svg";
import news_logo from "../../assets/logo/news-logo.svg";
import live_logo from "../../assets/logo/live-logo.svg";
import note_logo from "../../assets/logo/note-logo.svg";
import home_logo_active from "../../assets/logo/home-logo-active.svg";
import news_logo_active from "../../assets/logo/news-logo-active.svg";
import live_logo_active from "../../assets/logo/live-logo-active.svg";
import note_logo_active from "../../assets/logo/note-logo-active.svg";

function NavbarFooter() {

    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location.pathname);

    useEffect(() => {
        setActiveLink(location.pathname);
    }, [location.pathname]);

    const linkClasses = (path) =>
        `list-item-hov-phone ${activeLink === path ? "active" : ""}`;

    const getImage = (path) => {
        switch (path) {
            case "/":
                return activeLink === path ? home_logo : home_logo_active;
            case "/my-diary":
                return activeLink === path ? note_logo : note_logo_active;
            case "/live-news":
                return activeLink === path ? live_logo : live_logo_active;
            case "/news":
                return activeLink === path ? news_logo : news_logo_active;
            default:
                return home_logo;
        }
    };

    return (
        <div className='navbar-footer lg:hidden flex justify-center items-center'>
            <ul className="flex flex-row w-full">
                <li className={linkClasses("/my-diary")}>
                    <Link

                        to="/my-diary"
                        className="flex flex-row lg:gap-4 justify-center items-center"
                    >
                        <h2 className={`text-white ${activeLink === "/my-diary" ? "" : "hidden-h2"}`}>
                            مذكرات
                        </h2>
                        <img src={getImage("/my-diary")} alt="my-diary" />
                    </Link>
                </li>
                <li className={linkClasses("/news")}>
                    <Link

                        to="/news"
                        className="flex flex-row lg:gap-4 justify-center items-center"
                    >
                        <h2 className={`text-white pl-3 ${activeLink === "/news" ? "" : "hidden-h2"}`}>
                            الأخبار
                        </h2>
                        <img src={getImage("/news")} alt="news" />
                    </Link>
                </li>
                <li className={linkClasses("/live-news")}>
                    <Link

                        to="/live-news"
                        className="flex flex-row justify-center items-center"
                    >
                        <h2 className={`text-white pl-3 lg:pr-3 ${activeLink === "/live-news" ? "" : "hidden-h2"}`}>
                            مباشر
                        </h2>
                        <img src={getImage("/live-news")} alt="live-news" />
                    </Link>
                </li>
                <li className={linkClasses("/")}>
                    <Link

                        to="/"
                        className="flex flex-row lg:gap-4 justify-center items-center"
                    >
                        <h2 className={`text-white ${activeLink === "/" ? "" : "hidden-h2"}`}>
                            الرئيسية
                        </h2>
                        <img src={getImage("/")} alt="Home" />
                    </Link>
                </li>
            </ul>
        </div>
    );
}

export default NavbarFooter;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import materialsImage from "../../assets/images/materials.png";

const Section = () => {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSectionData = async () => {
      try {
        const response = await fetch("https://admin.aliwehbedu.com/v1_0_0-main/get-sections");
        const data = await response.json();
        
        if (data.success) {
          const updatedCards = data.sections.map((section) => ({
            image: section.main_image ? `https://admin.aliwehbedu.com${section.main_image}` : materialsImage,
            text: section.title,
            id: section.id,
            type: section.type,
            slug: section.slug,
          }));
          setCards(updatedCards);
        } else {
          console.error('Failed to fetch section data:', data.error);
        }
      } catch (error) {
        console.error("Error fetching section data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSectionData();
  }, []);

  const handleCardClick = (card) => {
    const { id, type, text, slug } = card;

    if (type === 10986) {
      navigate(`/paperwork/${id}/${type}`, { state: { sectionName: text, id, type } });
    } else if (type === 10987) {
      navigate(`/voting-history/${id}/0/0`, { state: { sectionName: text, id, type } });
    } else if (type === 10988) {
      navigate(`/qa`, { state: { sectionName: text, id, type } });
    } else if (type === 10996) {
      navigate(`/search-exams/${id}/${type}` , { state: { sectionName: text, id, type } });
    } else {
      navigate(`/section-details/${slug}`, { state: { sectionName: text, id, type, slug } });
    }
  };

  return (
    <div className="grid bg-white grid-cols-2 lg:grid-cols-4 gap-8 p-4 md:p-12 pr- flex-wrap" style={{ direction: 'rtl' }}>
      {loading ? (
        [...Array(8)].map((_, index) => (
          <div key={index} className="w-full h-48 bg-gray-200 rounded-lg flex items-center justify-center">
            <Skeleton width={200} height={30} />
          </div>
        ))
      ) : (
        cards.map((card, index) => (
          <div
            key={index}
            onClick={() => handleCardClick(card)}
            className="relative md:w-full h-26 md:h-48 bg-cover bg-[#003366] bg-center rounded-lg transform transition-transform duration-300 hover:scale-105 cursor-pointer"
          >
            <img src={card.image} className="w-full h-full rounded object-cover opacity-20" alt="" />
            <div className="absolute inset-0 flex items-center justify-center rounded-lg">
              <h2 className="text-white text-md md:text-xl lg:text-xl font-montserrat-semibold text-center px-4" style={{ maxWidth: '200px' }}>
                {card.text}
              </h2>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default Section;

import React, { useState ,useEffect } from 'react';
import NewsNavbar from '../../components/news-navbar/NewsNavbar';
import NewsSlider from '../../components/news-slider/NewsSlider';
import LatestNews from '../../components/latest-news/LatestNews';

function News() {
  const [selectedCategory, setSelectedCategory] = useState({ id: null, name: null });

  const handleCategorySelect = (categoryId, categoryName) => {
    setSelectedCategory({ id: categoryId, name: categoryName });
  };

  
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  return (
    <div className="bg-[#003366] md:min-h-lvh">
      <NewsNavbar onCategorySelect={handleCategorySelect} />
      <NewsSlider selectedCategory={selectedCategory.id} />
      <LatestNews selectedCategory={selectedCategory} />
    </div>
  );
}

export default News;

import React, { useEffect, useState, forwardRef } from 'react';
import "./rightSide.css";
import cv_logo from "../../assets/logo/cv-logo.svg";
import about_logo from "../../assets/logo/call-logo.svg";
import Logo from "../../assets/logo/logo.png";
import { Link } from 'react-router-dom';
import SearchBar from '../search/SearchBar';

// Global variables to cache data
let cachedSocialMediaLinks = [];
let cachedCvData = null;
let cachedUsefulLinksData = [];

const RightSide = forwardRef(({ setToggle }, ref) => {
  const [socialMediaLinks, setSocialMediaLinks] = useState(cachedSocialMediaLinks);
  const [cvData, setCvData] = useState(cachedCvData);
  const [usefulLinksData, setUsefulLinksData] = useState(cachedUsefulLinksData);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    if (cachedSocialMediaLinks.length === 0) {
      fetch("https://admin.aliwehbedu.com/v1_0_0-main/get-sections")
        .then((response) => response.json())
        .then((data) => {
          if (data && data.social_media) {
            cachedSocialMediaLinks = data.social_media;
            setSocialMediaLinks(cachedSocialMediaLinks);
          }
        })
        .catch((error) => console.error("Error fetching social media links:", error));
    } else {
      setSocialMediaLinks(cachedSocialMediaLinks);
    }
  }, []);

  const handleCloseSidebar = () => {
    if (setToggle) {
      setToggle(false);
    }
  };

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [searchResults, setSearchResults] = useState([]);

  const handleSearchResults = (results) => {
    setSearchResults(results);
  };

  return (
    <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`} ref={ref}>
      <div className="overlay" onClick={handleCloseSidebar} style={{ display: isSidebarOpen ? 'none' : 'block' }}></div>
      <div className='sidebar-header flex flex-col items-center bg-text-blueNav'>
        <img src={Logo} alt="Logo" />
        <h2>Aliwehbedu</h2>
        <div className="w-full flex justify-center mt-4">
          <SearchBar onSearch={handleSearchResults} />
        </div>
      </div>

      <div className='w-full sidebar-home'>
        <div className='sidebar-section'>
          <Link to="/about" onClick={handleCloseSidebar}>
            <div className="flex flex-row justify-center items-center gap-3">
              <span className="text-text-blueNav">من نحن؟</span>
              <img src={about_logo} alt="About" />
            </div>
          </Link>
        </div>
        <hr className='border-b-2 ' />
      </div>

      <div className='w-full sidebar-home'>
        <div className='sidebar-section'>
          <Link to="/contact" onClick={handleCloseSidebar}>
            <div className="flex flex-row justify-center items-center gap-3">
              <span className="text-text-blueNav">إتصل بنا</span>
              <img src={cv_logo} alt="Contact" />
            </div>
          </Link>
        </div>
        <hr className='border-b-2 ' />
      </div>

      <div className='sidebar-social-media absolute bottom-0' dir='rtl'>
        {socialMediaLinks.map((link) => (
          <a key={link.id} href={link.link} target="_blank" rel="noopener noreferrer" onClick={handleCloseSidebar}>
            <img src={`https://admin.aliwehbedu.com${link.main_image}`} className="social-media-icon" alt={link.id} />
          </a>
        ))}
      </div>
    </div>
  );
});

export default RightSide;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AiOutlineSearch, AiOutlineLoading3Quarters, AiOutlineClose } from 'react-icons/ai';

function SearchBar({ onSearch }) {
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSearch = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.get(`https://admin.aliwehbedu.com/v1_0_0-main/get-news/?search=${query}`);
      if (response.data.success) {
        onSearch(response.data.data);
        navigate(`/search?query=${query}`);
        setQuery(''); // Clear the query after successful search
      } else {
        console.error('Search failed:', response.data.error);
        onSearch([]);
        navigate(`/search?query=${query}`);
      }
    } catch (error) {
      console.error('Error during search:', error);
      onSearch([]);
      navigate(`/search?query=${query}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClear = () => {
    setQuery('');
    onSearch([]);
  };

  return (
    <form className="form relative lg:mr-20 flex justify-center" onSubmit={handleSearch}>
      <div className="relative flex justify-center items-center w-full">
        <input
          className={`w-[200px] lg:w-[410px] text-[#003366] border-[#003366] rounded-full px-8 py-3 pr-14 border-2 focus:outline-none focus:border-blue-700 placeholder-gray-400 shadow-md text-right`}
          placeholder="...البحث"
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          disabled={isLoading}
        />
        {query && (
          <button
            type="button"
            className="absolute right-3 lg:right-3 top-1/2 transform -translate-y-1/2 p-2 bg-red-500 text-white rounded-full flex items-center justify-center shadow-md hover:bg-red-700 transition duration-300"
            onClick={handleClear}
          >
            <AiOutlineClose />
          </button>
        )}
        <button
          type="submit"
          className="absolute left-4 lg:left-6 top-1/2 transform -translate-y-1/2 p-2 bg-[#003366] text-white rounded-full flex items-center justify-center shadow-md hover:bg-blue-700 transition duration-300"
          disabled={isLoading}
        >
          {isLoading ? (
            <AiOutlineLoading3Quarters className="animate-spin" />
          ) : (
            <AiOutlineSearch />
          )}
        </button>
      </div>
    </form>
  );
}

export default SearchBar;

import React, { useState, useEffect } from 'react';
import pause from "../../assets/logo/pause.svg"
import play from "../../assets/logo/play.svg"
import stop from "../../assets/logo/stop.svg"
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Timer = () => {
    const [time, setTime] = useState({ hours: 0, minutes: 0, seconds: 0 });
    const [isActive, setIsActive] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let interval = null;

        if (isActive) {
            interval = setInterval(() => {
                setTime((prevTime) => {
                    let { hours, minutes, seconds } = prevTime;
                    if (seconds === 59) {
                        seconds = 0;
                        if (minutes === 59) {
                            minutes = 0;
                            hours += 1;
                        } else {
                            minutes += 1;
                        }
                    } else {
                        seconds += 1;
                    }
                    return { hours, minutes, seconds };
                });
            }, 1000);
        } else {
            clearInterval(interval);
        }

        setLoading(false);

        return () => clearInterval(interval);
    }, [isActive]);

    const handleStartStop = () => {
        setIsActive(!isActive);
    };

    const handleReset = () => {
        setIsActive(false);
        setTime({ hours: 0, minutes: 0, seconds: 0 });
    };

    const formatTime = (time) => {
        return time.toString().padStart(2, '0');
    };

    return (
        <div className="flex items-center space-x-2">
            {loading ? (
                <div className="flex space-x-1">
                    <Skeleton width={40} height={40} />
                    <Skeleton width={10} height={40} />
                    <Skeleton width={40} height={40} />
                    <Skeleton width={10} height={40} />
                    <Skeleton width={40} height={40} />
                </div>
            ) : (
                <div className="flex space-x-1">
                    <span className="bg-blue-900 w-10 text-white p-2 rounded">{formatTime(time.hours)}</span>
                    <span className="text-blue-900">:</span>
                    <span className="bg-blue-900 w-10 text-white p-2 rounded">{formatTime(time.minutes)}</span>
                    <span className="text-blue-900">:</span>
                    <span className="bg-blue-900 w-10 text-white p-2 rounded">{formatTime(time.seconds)}</span>
                </div>
            )}
            <button
                onClick={handleStartStop}
                className={`${isActive ? 'bg-yellow-500' : 'bg-green-500'} text-white w-10 h-10 rounded-full flex justify-center items-center`}
            >
                {isActive ? <img src={stop} className='w-4' alt="Stop icon" /> : <img src={play} alt="Play icon" />}
            </button>

            <button onClick={handleReset} className="bg-red-500 text-white w-10 h-10 rounded-full flex justify-center items-center">
                <img src={pause} alt="Pause icon" />
            </button>
        </div>
    );
};

export default Timer;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import noData from '../../assets/images/nodata.png';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// Global variable to store cached content
let cachedContent = '';

function AboutUs() {
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (cachedContent) {
                    // Use cached content if available
                    setContent(cachedContent);
                } else {
                    const response = await axios.get('https://admin.aliwehbedu.com/v1_0_0-main/get-owner-data');
                    if (response.data.success && response.data.data.length > 0) {
                        const rawContent = response.data.data[0].who_are_we;
                        const formattedContent = formatContent(rawContent);
                        cachedContent = formattedContent; // Cache the content
                        setContent(formattedContent);
                    }
                }
            } catch (error) {
                console.error('Error fetching the data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const formatContent = (rawContent) => {
        let formattedContent = rawContent
            .replace(/\r\n/g, '\n')
            .replace(/\n\t/g, '\n    ')
            .replace(/\n\n/g, '<p>')
            .replace(/\n/g, '<br/>')
            .replace(/\t/g, '&emsp;');

        formattedContent = formattedContent.split('<p>').map(paragraph => {
            if (paragraph.startsWith('•')) {
                return `<ul><li>${paragraph.replace(/•\s/g, '').replace(/<br\/>/g, '</li><li>')}</li></ul>`;
            } else {
                return `<p>${paragraph.trim()}</p>`;
            }
        }).join('');

        return formattedContent;
    };

    return (
        <div className="flex flex-col justify-start items-center mb-10 md:min-h-lvh">
            <div className="flex flex-col justify-evenly items-center w-full p-4 text-center text-white font-montserrat-semibold">
                <div className="tab-white">
                    <ul>
                        <li className="list-item active">
                            <b className="left-curve"></b>
                            <b className="right-curve"></b>

                            <h2 className="text-white">من نحن</h2>
                        </li>
                    </ul>
                </div>
                <div className="bg-white border-4 text-[#003366] border-[#003366] lg:w-[900px] rounded-2xl py-6
                 px-6 text-right space-y-14">
                    {loading ? (
                        <Skeleton width={830} height={2000} />
                    ) : content ? (
                        <div
                            className="font-montserrat-light"
                            style={{ lineHeight: '2', direction: 'rtl' }}
                            dangerouslySetInnerHTML={{ __html: content }}
                        />
                    ) : (
                        <div className="flex flex-col justify-center items-center w-full h-full">
                    <img src={noData} alt="No data" className="max-w-full max-h-full" />
                    <p className="text-center text-xl font-montserrat-bold text-[#003366] mt-4">لا توجد بيانات</p>
                  </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AboutUs;


import React, { createContext, useContext, useState } from 'react';

const SkeletonContext = createContext();

export const useSkeleton = () => {
  return useContext(SkeletonContext);
};

export const SkeletonProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);

  const value = {
    isLoading,
    setIsLoading,
  };

  return (
    <SkeletonContext.Provider value={value}>
      {children}
    </SkeletonContext.Provider>
  );
};

import React, { useEffect } from 'react';
import AboutUs from '../../components/about-us/AboutUs';

function About() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='md:min-h-lvh'>
            <AboutUs />
        </div>
    );
}

export default About;

import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Voting from "../../components/voting/Voting";
import { FaCircleArrowRight } from "react-icons/fa6";
import noData from '../../assets/images/nodata.png'; 

const History = () => {
  const { sectionid, useruuid, offset } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const { sectionName } = state;
  const [hasData, setHasData] = useState(true); 

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBackClick = () => {
    navigate(-1); 
  };

  return (
    <div className="bg-blueborder px-14 pb-14 md:min-h-lvh">
      <div className="flex flex-row justify-between items-center w-full font-montserrat-semibold">
        <div className="tab">
          <ul>
            <li className="list-item active">
              <b className="left-curve"></b>
              <b className="right-curve"></b>
              <h2 className="text-hoverText">{sectionName}</h2>
            </li>
          </ul>
        </div>
        <div className="mb-16">
          <button onClick={handleBackClick} className="text-white absolute right-5 my-4 rounded-full focus:outline-none">
            <FaCircleArrowRight className="text-4xl text-white" />
          </button>
        </div>
      </div>
      <div className="p-6 bg-white border rounded-lg">
        {hasData ? (
          <Voting sectionId={sectionid} userUuid={useruuid} offset={offset} setHasData={setHasData} />
        ) : (
          <div className="flex flex-col justify-center items-center w-full h-full">
            <img src={noData} alt="No data" className="max-w-full max-h-full" />
            <p className="text-center text-xl font-montserrat-bold text-[#003366] mt-4">لا توجد بيانات</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default History;

import React from 'react'
import notFound from "../../assets/images/notFound.png"

function NotFound() {
    return (
        <div className='relative w-full h-screen'>
            <img src={notFound} className='absolute top-0 left-0 w-full h-full object-cover' alt="Not Found" />
            <div className='absolute inset-0 flex flex-col justify-center items-center text-center text-white bg-black bg-opacity-50'>
                <h1 className='text-4xl md:text-6xl font-bold mb-4'>Oops! Page Not Found</h1>
                <h2 className='text-2xl md:text-4xl mb-2'>We’re sorry, but the page you’re looking for doesn’t exist.</h2>
                <h3 className='text-xl md:text-2xl'>It might have been removed, renamed, or did not exist.</h3>
            </div>
        </div>
    )
}

export default NotFound
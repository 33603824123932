import React, { useState, useEffect, useRef } from "react";
import { FaRegCirclePlay } from "react-icons/fa6";
import { IoIosCloseCircle } from "react-icons/io";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
import Slider from "react-slick";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

let cachedSliderItems = null;

function HomeSlider() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [mediaSrc, setMediaSrc] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const [currentSlide, setCurrentSlide] = useState(0);
  const [hoverMessage, setHoverMessage] = useState("");
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [sliderItems, setSliderItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 640);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchSliderItems = async () => {
      if (cachedSliderItems) {
        // Use cached data if available
        setSliderItems(cachedSliderItems);
        setLoading(false);
      } else {
        try {
          const response = await fetch("https://admin.aliwehbedu.com/v1_0_0-main/get-main-slider");
          const data = await response.json();
          if (data.success) {
            const fetchedItems = data.main_slider.map((item) => {
              let type = "image";
              let src = `https://admin.aliwehbedu.com${item.main_image}`;

              if (item.main_image === null && item.attachments.length > 0) {
                const attachment = item.attachments[0];
                if (attachment.file_extension === "mp4") {
                  type = "video";
                  src = `https://admin.aliwehbedu.com${attachment.file_path}`;
                } else {
                  src = `https://admin.aliwehbedu.com${attachment.file_path}`;
                }
              }

              return {
                id: item.id,
                title: item.slider_name || "",
                image: type === "image" ? src : null,
                videoSrc: type === "video" ? src : null,
                link: item.external_link,
                type: type,
              };
            });

            cachedSliderItems = fetchedItems;  
            setSliderItems(fetchedItems);
            setLoading(false);
          }
        } catch (error) {
          console.error("Error fetching slider items:", error);
        }
      }
    };

    fetchSliderItems();
  }, []);

  const handleVideoClick = (title, src) => {
    setVideoTitle(title);
    setMediaSrc(src);
    setIsVideoOpen(true);
  };

  const handleCloseModal = () => {
    setIsVideoOpen(false);
    setIsImageModalOpen(false);
  };

  const handleItemClick = (item) => {
    if (item.type === "video") {
      handleVideoClick(item.title, item.videoSrc);
    } else if (item.type === "image") {
      if (item.link) {
        window.open(item.link, "_blank", "noopener,noreferrer");
      } else {
        setMediaSrc(item.image);
        setIsImageModalOpen(true);
      }
    }
  };

  const handleDotClick = (index) => {
    setCurrentSlide(index);
  };

  const handleMouseEnter = (item, event) => {
    let message = "";
    if (item.type === "video") {
      message = "Play video";
    } else if (item.link) {
      message = "Open link";
    } else {
      message = "View image";
    }
    setHoverMessage(message);
    setMousePosition({ x: event.clientX, y: event.clientY });
  };

  const handleMouseMove = (event) => {
    setMousePosition({ x: event.clientX, y: event.clientY });
  };

  const handleMouseLeave = () => {
    setHoverMessage("");
  };

  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 30000,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
  };

  const goToPrevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const goToNextSlide = () => {
    sliderRef.current.slickNext();
  };

  return (
    <div className="">
      <button className="prev-btn absolute top-64 z-50 md:block hidden ml-3" onClick={goToPrevSlide}><FaArrowAltCircleLeft size={40} color="white" /></button>
      <Slider {...settings} ref={sliderRef} className="slider-container">
        {loading ? (
          Array.from({ length: 3 }).map((_, index) => (
            <div key={index} className="flex flex-col h-[200px] items-center relative w-full md:h-full">
              <Skeleton className="w-full sm:w-full h-[200px] md:h-[500px]" />
            </div>
          ))
        ) : (
          sliderItems.map((item, index) => (
            <div
              key={item.id}
              className="flex flex-col items-center relative w-full md:h-full"
              onMouseEnter={(event) => handleMouseEnter(item, event)}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
            >
              {currentSlide === index && (
                <>
                  {item.type === "image" ? (
                    <div className="relative flex flex-col items-center w-full h-full">
                      <img
                        src={item.image}
                        className="w-full h-[200px] md:h-[500px] object-cover cursor-pointer"
                        alt={item.title}
                        onClick={() => handleItemClick(item)}
                        style={{ borderRadius: 0 }}
                      />
                      <div className="absolute flex items-center bottom-0 w-full bg-opacity-70 bg-gray-500 py-3 px-6">
                        <div className="flex justify-end space-x-1 mr-6">
                          {sliderItems.map((_, dotIndex) => (
                            <div
                              key={dotIndex}
                              className={`w-4 h-4 rounded-full ${currentSlide === dotIndex ? 'bg-white' : 'bg-gray-400'}`}
                            ></div>
                          ))}
                        </div>

                        <div className="flex flex-1 justify-center">
                          {item.title && (
                            <p className="font-montserrat-semibold sm:text-md md:text-3xl text-center text-white">
                              {item.title}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="relative flex flex-col items-center w-full h-full"
                      onClick={() => handleItemClick(item)}
                    >
                      <video
                        className="w-full sm:w-full md:w-full h-[200px] md:h-[500px] object-cover"
                        src={item.videoSrc}
                        alt={item.title}
                        muted
                        style={{ borderRadius: 0 }}
                      />
                      <FaRegCirclePlay
                        className="absolute text-white w-20 h-20 md:w-32 md:h-32 cursor-pointer"
                        style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                      />
                      <div className="absolute flex items-center bottom-0 w-full bg-opacity-70 bg-gray-500 py-3 px-6">
                        <div className="flex justify-end space-x-1 mr-6">
                          {sliderItems.map((_, dotIndex) => (
                            <div
                              key={dotIndex}
                              className={`w-4 h-4 rounded-full ${currentSlide === dotIndex ? 'bg-white' : 'bg-gray-400'}`}
                            ></div>
                          ))}
                        </div>

                        <div className="flex flex-1 justify-center">
                          {item.title && (
                            <p className="font-montserrat-semibold sm:text-md md:text-3xl text-center text-white">
                              {item.title}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          ))
        )}
      </Slider>
      <button className="next-btn absolute top-64 right-0   md:block hidden mr-3" onClick={goToNextSlide}><FaArrowAltCircleRight size={40} color="white" /></button>
      {hoverMessage && (
        <div
          className="absolute text-white bg-gray-800 px-2 py-1 rounded"
          style={{ top: mousePosition.y + 20, left: mousePosition.x + 20 }}
        >
          {hoverMessage}
        </div>
      )}
      {isVideoOpen && (
  <div className="fixed inset-0 mt-8 flex items-center justify-center bg-black bg-opacity-75 z-40">
    <div className="relative bg-white p-4 rounded-lg w-11/12 md:w-4/5 lg:w-3/4 xl:w-2/3">
      <button className="absolute top-2 mb-6 right-4 text-red-500 mt-1" onClick={handleCloseModal}>
        <IoIosCloseCircle className="w-10 h-10 " />
      </button>
      <h2 className="text-2xl font-montserrat-semibold text-[#003366] mb-4">
        {videoTitle}
      </h2>
      <video controls autoPlay className="w-full mt-6 h-[500px]">
        <source src={mediaSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
)}

      {isImageModalOpen && (
        <div className="fixed inset-0 mt-8 flex items-center justify-center bg-black bg-opacity-75 z-50">
          <div className="relative bg-white p-4 rounded-lg w-10/12 md:w-3/4 lg:w-2/3 xl:w-1/2">
            <button className="absolute right-0 top-0 mb-8 text-red-500 mt-1" onClick={handleCloseModal}>
              <IoIosCloseCircle className="w-8 h-8 " />
            </button>
            <h2 className="text-md md:text-2xl mr-10 mt-1 font-montserrat-semibold text-[#003366] mb-4 text-right rtl">
              {sliderItems.find((item) => item.image === mediaSrc)?.title}
            </h2>
            <img src={mediaSrc} alt={videoTitle} className="w-full mt-6 h-auto max-h-[800px] max-w-[800px]" style={{ borderRadius: 0 }} />
          </div>
        </div>
      )}
    </div>
  );
}

export default HomeSlider;
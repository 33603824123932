import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// Global variable to store cached news categories
let cachedNewsCategories = [];

function NewsNavbar({ onCategorySelect }) {
  const [activeItem, setActiveItem] = useState("أبرز العناوين");
  const [newsCategories, setNewsCategories] = useState(cachedNewsCategories);
  const [loading, setLoading] = useState(!cachedNewsCategories.length);

  useEffect(() => {
    // Fetch news categories only if not already cached
    if (!cachedNewsCategories.length) {
      axios.get('https://admin.aliwehbedu.com/v1_0_0-main/get-news-categories')
        .then(response => {
          if (response.data.success) {
            const categories = response.data.categories;
            // Update newsCategories state to store objects with both id and name
            setNewsCategories([{ id: null, name: "أبرز العناوين" }, ...categories]);
            cachedNewsCategories = [{ id: null, name: "أبرز العناوين" }, ...categories]; // Cache the news categories
          } else {
            console.error('Failed to fetch categories:', response.data.error);
          }
        })
        .catch(error => {
          console.error('Error fetching categories:', error);
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, []); 

  const handleClick = (id, name) => {
    setActiveItem(name); // Update activeItem with category name
    onCategorySelect(id, name); // Pass category id and name to onCategorySelect
    console.log('Selected category:', id, name); // Log selected category id and name
  };

  // Render loading skeleton if data is still loading
  if (loading) {
    return (
      <div className="w-full bg-white">
        <div className="flex flex-row md:flex-wrap overflow-x-auto no-scrollbar" style={{ direction: 'rtl' }}>
          <Skeleton height={70} width={150} count={newsCategories.length} />
        </div>
        <style jsx>{`
          .no-scrollbar {
            -ms-overflow-style: none; 
            scrollbar-width: none;  
          }
          .no-scrollbar::-webkit-scrollbar {
            display: none;  
          }
          @media (min-width: 768px) {
            .flex-wrap {
              flex-wrap: wrap;
            }
          }
        `}</style>
      </div>
    );
  }

  return (
    <div className="w-full bg-white">
      <div className="flex flex-row md:flex-wrap overflow-x-auto no-scrollbar" style={{ direction: 'rtl' }}>
        {newsCategories.map((item, index) => (
          <div
            key={index}
            className={`cursor-pointer md:text-xl font-montserrat-regular md:h-[70px] flex items-center justify-center py-2 px-4 transition duration-300 ${
              activeItem === item.name ? 'bg-[#FF0000] text-white' : 'bg-white text-[#003366]'
            } ${index !== newsCategories.length - 1 ? 'border-l border-gray-400' : ''}`}
            style={{ flex: '1 0 auto', minWidth: '150px' }} 
            onClick={() => handleClick(item.id, item.name)} // Pass id and name
          >
            {item.name}
          </div>
        ))}
      </div>
      <style jsx>{`
        .no-scrollbar {
          -ms-overflow-style: none; 
          scrollbar-width: none;  
        }
        .no-scrollbar::-webkit-scrollbar {
          display: none;  
        }
        @media (min-width: 768px) {
          .flex-wrap {
            flex-wrap: wrap;
          }
        }
      `}</style>
    </div>
  );
}

export default NewsNavbar;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { ThreeCircles } from "react-loader-spinner";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import noData from '../../assets/images/nodata.png';
import axios from 'axios';
import { formatDistanceToNow } from 'date-fns';
import arLocale from 'date-fns/locale/ar-SA';

// Global variable to store cached news data
let cachedNewsData = [];

function LatestNews({ selectedCategory }) {
  const [newsData, setNewsData] = useState(cachedNewsData);
  const [filteredNews, setFilteredNews] = useState([]);
  const [loading, setLoading] = useState(!cachedNewsData.length);
  const [loadingMore, setLoadingMore] = useState(false);
  const [offset, setOffset] = useState(0);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const NEWS_INCREMENT = 20; // Changed to 20 for 20 news items per load

  const fetchNews = async (categoryId, offset = 0) => {
    try {
      setLoadingMore(true);
      const response = await axios.get('https://admin.aliwehbedu.com/v1_0_0-main/get-news', {
        params: {
          category_id: categoryId,
          offset: offset,
          limit: NEWS_INCREMENT
        }
      });
      console.log('API Response:', response.data);
      const { data } = response.data;
      if (offset === 0) {
        setNewsData(data);
        setFilteredNews(data);
      } else {
        setNewsData(prevNewsData => [...prevNewsData, ...data]);
        setFilteredNews(prevNews => [...prevNews, ...data]);
      }
      setAllDataLoaded(data.length < NEWS_INCREMENT);
    } catch (error) {
      console.error('Error fetching news:', error);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    setNewsData([]);
    setFilteredNews([]);
    setOffset(0);
    setAllDataLoaded(false);
    if (selectedCategory.id === null) {
      fetchNews(null, 0);
    } else {
      fetchNews(selectedCategory.id, 0);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (offset > 0) {
      fetchNews(selectedCategory.id, offset);
    }
  }, [offset, selectedCategory]);

  const loadMoreNews = () => {
    setOffset(prevOffset => prevOffset + NEWS_INCREMENT);
  };

  return (
    <div className='news-slider-container w-full max-w-[1383px] mx-auto'>
      <div className="w-full font-montserrat-semibold text-right">
        <div className="tab lg:pr-16 pr-12">
          <ul className="flex justify-end">
            <li className="list-item active relative">
              <b className="left-curve absolute right-full"></b>
              <b className="right-curve absolute left-full"></b>
              <h2 className="text-hoverText">{selectedCategory.name || "أبرز العناوين"}</h2>
            </li>
          </ul>
        </div>
      </div>
      <div className="bg-[#003366] flex justify-center relative">
        <div className="news-slider-container w-full max-w-[1383px] pt-4 mb-14 bg-white rounded-lg overflow-hidden shadow-lg mx-2 md:mx-0">
          <div className="flex justify-center items-center p-6" style={{ direction: 'rtl', minHeight: '300px' }}>
            {loading ? (
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 w-full">
                {[...Array(NEWS_INCREMENT)].map((_, index) => (
                  <div key={index} className="text-black">
                    <div className="flex lg:relative flex-col sm:flex-row items-center justify-start w-full border-b border-gray-300 pb-4 hover:scale-105 transition-transform duration-300 ease-in-out cursor-pointer">
                      <div className="lg:relative image-container md:mr-2 md:ml-10 sm:w-40 h-32 sm:h-30 rounded-md mb-4 sm:mb-0 overflow-hidden w-[300px]">
                        <Skeleton height={200} />
                      </div>
                      <div className="sm:absolute sm:right-[200px] text-center sm:text-right w-full sm:w-auto mr-2">
                        <p className="mb-1 text-right text-[#003366] text-[20px] w-full sm:w-auto sm:mr-8 font-montserrat-bold"><Skeleton /></p>
                        <p className="text-right text-[#808080] text-[12px] sm:mr-8 mt-2 font-montserrat-semibold sm:text-right"><Skeleton width={100} /></p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : filteredNews.length > 0 ? (
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 w-full">
                {filteredNews.map((news, index) => (
                  <Link key={index} to={`/news-details/${news.id}`} className="text-black"> {/* Use news.id instead of news.slug */}
                    <div className="flex lg:relative flex-col sm:flex-row items-center justify-start w-full border-b border-gray-300 pb-4 hover:scale-105 transition-transform duration-300 ease-in-out cursor-pointer">
                      <div className="image-container md:mr-2 md:ml-10 sm:w-40 sm:h-30 rounded-md mb-4 sm:mb-0 overflow-hidden w-[300px] md:h-[100px] h-[150px]">
                        <img
                          src={`https://admin.aliwehbedu.com${news.main_image}`}
                          alt={`News ${index + 1}`}
                          className="h-full object-cover w-full"
                        />
                      </div>
                      <div className="sm:absolute sm:right-[200px] text-center sm:text-right w-full sm:w-auto mr-2">
                        <p className="mb-1 text-right text-[#003366] text-xs md:text-lg lg:text-lg w-full sm:w-auto 
                        sm:mr-8 font-montserrat-bold">{news.title}</p>
                        <p className="text-right text-[#808080] text-xs md:text-lg lg:text-lg sm:mr-8 mt-2 font-montserrat-semibold sm:text-right" dir='ltr'>
                          منذ {formatDistanceToNow(new Date(news.created_at), { locale: arLocale })}
                        </p>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center w-full h-full">
                <img src={noData} alt="No data" className="max-w-full max-h-full" />
                <p className="text-center text-xl font-montserrat-bold text-[#003366] mt-4">لا توجد بيانات</p>
              </div>
            )}
          </div>
          <div className="flex justify-center items-center">
            {loadingMore ? (
              <div className="flex justify-end items-center mt-4 mb-2">
                <ThreeCircles
                  visible={true}
                  height={100}
                  width={100}
                  color="#003366"
                  ariaLabel="three-circles-loading"
                  wrapperStyle={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                  }}
                />
              </div>
            ) : (
              !loading && !allDataLoaded && (
                <button
                  className="mt-4 bg-[#003366] text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105 mb-6"
                  onClick={loadMoreNews}
                >
                  عرض المزيد
                </button>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LatestNews;

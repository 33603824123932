import React from 'react'
import LiveNewsComponent from '../../components/live-news/LiveNewsComponent'

function LiveNews() {
    return (
        <div className='md:min-h-lvh'>
            <LiveNewsComponent />
        </div>
    )
}

export default LiveNews
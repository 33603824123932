import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import logo from "../../assets/logo/logo.png";
import { FaCircleArrowRight } from "react-icons/fa6";
import Timer from './Timer';
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function ExamPDF({ slug }) {
  const location = useLocation();
  const pdfPath = location.state?.pdfPath;
  const year = location.state?.year;
  const round = location.state?.round;
  const navigate = useNavigate();

  const [iframeWidth, setIframeWidth] = useState(getIframeWidth());
  const [loading, setLoading] = useState(true);
  const [loadingPdf, setLoadingPdf] = useState(false); 
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => setLoading(false), 1000);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIframeWidth(getIframeWidth());
      setIsSmallScreen(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setLoadingPdf(true); 
  }, [pdfPath]);

  function getIframeWidth() {
    return window.innerWidth <= 768 ? '100%' : '50%';
  }

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleViewPdfClick = () => {
    window.open(isSmallScreen ? `https://docs.google.com/viewer?url=https://admin.aliwehbedu.com${pdfPath}` : `https://admin.aliwehbedu.com${pdfPath}`, '_blank');
  };

  const handlePdfLoad = () => {
    setLoadingPdf(false); 
    sessionStorage.setItem('cachedPdfUrl', pdfPath);
  };

  return (
    <div className="flex flex-col justify-start items-center bg-gray-100 min-h-screen">
      <header className='w-full h-20 bg-[#003366] flex justify-between items-center px-6 shadow-md'>
        <Link to="/" className="flex items-center text-3xl font-bold text-white">
          <div className='flex justify-center items-center'>
            <img src={logo} alt="Logo" className="w-10 h-10" />
            <span className="ml-2 text-white font-semibold text-xl hidden md:block">Aliwehbedu</span>
          </div>
        </Link>
        <div className='text-white text-center justify-center font-semibold text-lg md:text-3xl'>
          <h1 className="md:mr-32">{`Year: ${year}, Round: ${round}`}</h1>
        </div>
        <button onClick={handleBackClick} className="text-white focus:outline-none">
          <FaCircleArrowRight className="text-3xl" />
        </button>
      </header>
      <main className="flex flex-col justify-center items-center w-full p-4 text-center text-gray-800 font-semibold">
        <div className="mb-4">
          <Timer />
        </div>
        <div className="flex flex-col justify-center items-center w-full h-full bg-white shadow-lg rounded-lg p-4">
          {loading ? (
            <>
              <Skeleton width={200} height={40} className="mb-4" />
              <Skeleton width={600} height={800} className="mb-4" />
              <Skeleton width={iframeWidth} height={800} />
            </>
          ) : (
            pdfPath ? (
              <>
                <button onClick={handleViewPdfClick} className="mt-4 mb-4 text-white bg-blue-500 hover:bg-blue-600 py-2 px-4 rounded-lg focus:outline-none">
                  View PDF in New Tab
                </button>
                {isSmallScreen && loadingPdf && ( 
                  <div className="flex justify-center items-center w-full h-full mt-16 mb-4" style={{ direction: 'rtl' }}>
                    <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 ml-4 border-blue-500"></div>
                    <span className="text-blue-500 font-montserrat-semibold text-lg ml-4">يرجى الانتظار...</span>
                  </div>
                )}
                <iframe
                  src={isSmallScreen ? `https://docs.google.com/viewer?url=https://admin.aliwehbedu.com${pdfPath}&embedded=true` : `https://admin.aliwehbedu.com${pdfPath}`}
                  style={{ width: iframeWidth, height: isSmallScreen ? '500px' : '1100px', border: 'none' }}
                  title="PDF Viewer"
                  onLoad={handlePdfLoad} 
                />
              </>
            ) : (
              <p>No PDF available</p>
            )
          )}
        </div>
      </main>
    </div>
  );
}

export default ExamPDF;

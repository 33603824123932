import React, { useState, useEffect } from 'react';
import { FaCircleCheck } from "react-icons/fa6";
import { IoCheckbox } from "react-icons/io5";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { browserName, osName } from 'react-device-detect';
import CryptoJS from 'crypto-js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Vote = () => {
  const [votes, setVotes] = useState({});
  const [selected, setSelected] = useState('');
  const [voted, setVoted] = useState(false);
  const [question, setQuestion] = useState('');
  const [options, setOptions] = useState([]);
  const [totalVotes, setTotalVotes] = useState(0);
  const [correctAnswer, setCorrectAnswer] = useState('');
  const [loading, setLoading] = useState(true);
  const [startingAt, setStartingAt] = useState('');
  const [votingId, setVotingId] = useState('');
  const [userIp, setUserIp] = useState('');
  const [userUuid, setUserUuid] = useState('');
  const [isVisible, setIsVisible] = useState(false); // New state variable for visibility

  useEffect(() => {
    const fetchVoteData = async () => {
      try {
        const savedVote = localStorage.getItem('votedOption');
        const savedBrowser = localStorage.getItem('browserName');
        const savedOs = localStorage.getItem('osName');
        const savedUserUuid = localStorage.getItem('user_uuid');

        console.log('LocalStorage Data:', { savedVote, savedBrowser, savedOs, savedUserUuid });

        if (savedVote && savedBrowser === browserName && savedOs === osName && savedUserUuid) {
          setSelected(savedVote);
          setVoted(true);
        }

        await fetchUserIp();

        const response = await fetch("https://admin.aliwehbedu.com/v1_0_0-main/get-active-voting-data/?section_id=10987&user_uuid=");
        const data = await response.json();

        if (data.success) {
          const votingData = data.data[0];
          setQuestion(votingData.question);
          setOptions(votingData.options);
          const initialVotes = votingData.options.reduce((acc, option) => {
            acc[option.text] = option.count;
            return acc;
          }, {});
          setVotes(initialVotes);
          setTotalVotes(votingData.options.reduce((acc, option) => acc + option.count, 0));
          setCorrectAnswer(votingData.correct_answer);
          setStartingAt(votingData.starting_at);
          setVotingId(votingData.id);
          setLoading(false);
          setIsVisible(true); // Set visibility to true if data is fetched successfully
        } else {
          setIsVisible(false); // Hide the vote-home div if data fetch is unsuccessful
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsVisible(false); // Hide the vote-home div on error
      }
    };

    fetchVoteData();
  }, []);

  useEffect(() => {
    if (userIp) {
      const userUuid = generateUserUuid(browserName, osName, userIp);
      setUserUuid(userUuid);
      localStorage.setItem('user_uuid', userUuid);
      localStorage.setItem('browserName', browserName);
      localStorage.setItem('osName', osName);
      localStorage.setItem('userIp', userIp);
    }
  }, [userIp]);

  const fetchUserIp = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      setUserIp(data.ip);
      console.log('User IP:', data.ip);
    } catch (error) {
      console.error('Error fetching user IP:', error);
    }
  };

  const handleVote = async (optionText, optionId) => {
    if (!voted) {
      setVotes((prevVotes) => ({ ...prevVotes, [optionText]: prevVotes[optionText] + 1 }));
      setSelected(optionText);
      setVoted(true);
      setTotalVotes(totalVotes + 1);

      localStorage.setItem('votedOption', optionText);

      const success = await sendVote(votingId, optionId, userUuid);
      if (success) {
        toast.success('Vote successfully recorded');
      } else {
        toast.error('Failed to record vote.');
      }
    }
  };

  const sendVote = async (votingId, optionId, userUuid) => {
    const url = `https://admin.aliwehbedu.com/v1_0_0-main/cast-vote?voting_id=${votingId}&option_id=${optionId}&user_uuid=${userUuid}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.success) {
        console.log('Vote submitted successfully');
        return true;
      } else {
        console.error('Error submitting vote:', data.message);
        return false;
      }
    } catch (error) {
      console.error('Error submitting vote:', error);
      return false;
    }
  };

  const generateUserUuid = (browser, os, ip) => {
    const description = `${browser}-${os}-${ip}`;
    const hash = CryptoJS.SHA256(description).toString(CryptoJS.enc.Hex);
    const truncatedHash = hash.substring(0, 30);
    return truncatedHash;
  };

  const getPercentage = (optionText) => {
    return ((votes[optionText] / totalVotes) * 100).toFixed(0);
  };

  const formatDate = (dateString) => {
    const options = { weekday: 'short', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  return (
    isVisible && (
      <div className='vote-home'>
        <ToastContainer />
        <div className='pt-12 flex ' style={{ direction: 'rtl' }}>
          <div className='text-5xl text-blue-400'>
            <IoCheckbox />
          </div>
          <div className='text-4xl text-white pr-4 font-montserrat-semibold'>
            <p>صوّت</p>
          </div>
        </div>
        <div className="my-10 grid sm:grid-cols-2 grid-cols-1" style={{ direction: 'rtl' }}>
          {loading ? (
            <div className="max-w-md mx-auto bg-[#FDF7E7] text-blueborder font-montserrat-semibold p-4 rounded-lg shadow-md text-right mb-8 space-y-6 w-full" style={{ direction: 'ltr' }}>
              <Skeleton height={150} count={5} />
            </div>
          ) : (
            <div className="max-w-md mx-auto bg-[#FDF7E7] text-blueborder font-montserrat-semibold p-4 rounded-lg shadow-md text-right mb-8 space-y-6" style={{ direction: 'ltr' }}>
              <h2 className="text-xl font-bold mb-4">{question}</h2>

              <div className="flex justify-end mb-2">
                <p className="text-gray-600 text-right mr-2">إختر إجابة واحدة</p>
                <div className={`text-${selected ? 'green' : 'gray'}-600`}><FaCircleCheck /></div>
              </div>
              <div className="space-y-8">
                {options.map((option, index) => (
                  <div key={index}>
                    <div className="flex justify-between">
                      <div>
                        <p className="text-gray-600 text-sm">{getPercentage(option.text)}% ({votes[option.text]})</p>
                      </div>
                      <div>
                        <label className="flex items-center justify-end mb-2">
                          {option.text}
                          <input
                            type="radio"
                            name="option"
                            value={option.text}
                            checked={selected === option.text}
                            onChange={() => handleVote(option.text, option.option_id)}
                            className={`mx-2 h-6 w-6 border-gray-500 border-opacity-50 border-2 rounded-full bg-transparent ${selected === option.text ? 'text-green-500' : 'text-gray-500'}`}
                            disabled={voted}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="bg-gray-200 h-2 rounded">
                      <div
                        className={`h-2 rounded ${option.text === correctAnswer ? 'bg-green-500' : 'bg-gray-500'}`}
                        style={{ width: `${getPercentage(option.text)}%` }}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>

              <p className="text-gray-500 text-sm mt-4 text-left">{formatDate(startingAt)}</p>

            </div>
          )}
        </div>
      </div>
    )
  );
};

export default Vote;

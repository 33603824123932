import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import LatestNews from '../latest-news/LatestNews';
import noData from '../../assets/images/nodata.png';
import SearchResult from './SearchResult';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function SearchPage() {
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const query = useQuery().get('query');

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        const response = await axios.get(`https://admin.aliwehbedu.com/v1_0_0-main/get-news/?search=${query}`);
        if (response.data.success) {
          setSearchResults(response.data.data);
        } else {
          console.error('Search failed:', response.data.error);
          setSearchResults([]);
        }
      } catch (error) {
        console.error('Error during search:', error);
        setSearchResults([]);
      } finally {
        setLoading(false);
      }
    };

    if (query) {
      fetchSearchResults();
    }
  }, [query]);

  return (
    <div className='news-slider-container bg-[#003366] w-full'>
      <div className="w-full h-full font-montserrat-semibold text-right">
        <div className=" lg:pr-16 pr-12">
          <ul className="flex justify-end">
            <li className="list-item active relative">
              <b className="left-curve absolute right-full"></b>
              <b className="right-curve absolute left-full"></b>
            </li>
          </ul>
        </div>
      </div>
      <div className="bg-[#003366] flex justify-center relative">
        {loading ? (
          <div className="flex flex-col justify-center items-center w-full h-full bg-white">
            <img src={noData} alt="No data" className="max-w-full max-h-full mt-12" />
            <p className="text-center text-xl font-montserrat-bold text-[#003366] mt-4 mb-12">لا توجد بيانات</p>
          </div>
        ) : searchResults.length > 0 ? (
          <SearchResult selectedCategory={query} newsData={searchResults} isSearch />
        ) : (
          <div className="flex flex-col bg-white py-12 justify-center items-center w-full h-full">
            <img src={noData} alt="No data" className="max-w-full max-h-full" />
            <p className="text-center text-xl font-montserrat-bold text-[#003366] mt-4">لا توجد بيانات</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchPage;
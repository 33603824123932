import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SkeletonProvider } from './components/skelton/SkeletonContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <SkeletonProvider>
    <App />
  </SkeletonProvider>
  // </React.StrictMode>
);

reportWebVitals();
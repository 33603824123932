import React, { useState, useEffect } from 'react';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import noData from '../../assets/images/nodata.png';
import arLocale from 'date-fns/locale/ar-SA';

const MoreDetails = ({ excludeSlug }) => {
  const [newsItems, setNewsItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch('https://admin.aliwehbedu.com/v1_0_0-main/get-news');
        const data = await response.json();
        if (data.success) {
          const filteredNews = data.data.filter(item => item.slug !== excludeSlug);
          const latestNews = filteredNews.slice(0, 4);
          setNewsItems(latestNews);
        }
      } catch (error) {
        console.error('Error fetching news:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchNews();
    window.scrollTo(0, 0);
  }, [excludeSlug]);

  const handleClick = (id) => {
    navigate(`/news-details/${id}`);
  };

  return (
    <div>
      <div className="w-full font-montserrat-semibold text-right">
        <div className="tab">
          <ul className="flex justify-end">
            <li className="list-item active relative mr-9">
              <b className="left-curve absolute right-full"></b>
              <b className="right-curve absolute left-full"></b>
              <h2 className="text-hoverText">آخر الأخبار</h2>
            </li>
          </ul>
        </div>
      </div>

      <div className="border-solid border-4 border-white rounded-lg text-white" dir="rtl">
        {isLoading ? (
          <div className="grid grid-cols-1 font-montserrat-semibold lg:grid-cols-2 gap-10 p-4">
            {[...Array(4)].map((_, index) => (
              <div key={index} className=" flex flex-col items-center border-b border-gray-400 pb-4 mb-4 
              transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg cursor-pointer">
                <Skeleton height={150} width={'100%'} />
                <Skeleton height={20} width={200} className="mt-2" />
                <Skeleton height={15} width={100} className="mt-1" />
              </div>
            ))}
          </div>
        ) : (
          <div className="font-montserrat-semibold p-4">
            {newsItems.length === 0 ? (
              <div className="flex flex-col justify-center items-center w-full h-full">
                <img src={noData} alt="No data" className="max-w-full max-h-full" />
                <p className="text-center text-xl font-montserrat-bold text-[#003366] mt-4">لا توجد بيانات</p>
              </div>
            ) : (
              <div className="grid grid-cols-1 lg:grid-cols-2 md:gap-10 gap-4">
                {newsItems.map((news, index) => (
                  <div
                    key={news.id}
                    className="flex flex-col items-center border-b border-gray-400 pb-4 mb-4 transition duration-300 ease-in-out
                     transform hover:scale-105 hover:shadow-lg cursor-pointer"
                    onClick={() => handleClick(news.id)}
                  >
                    <div className="flex h-full mr-2 mt-2 md:h-auto lg:relative flex-row sm:flex-row items-center justify-start w-full">
                      <div className="lg:relative w-32 h-16 image-container md:ml-10 sm:w-40 md:h-32 sm:h-30 rounded-md mb-4 sm:mb-0 overflow-hidden md:w-[180px]">
                        <img
                          src={`https://admin.aliwehbedu.com${news.main_image}`}
                          alt={`News ${index + 1}`}
                          className="lg:absolute h-full object-cover w-full md:w-[200px]"
                        />
                      </div>
                      <div className="lg:relative items-center md:w-[411px] w-full pr-4  h-16 mr-2 md:ml-10 sm:w-40 md:h-32 sm:h-30 mb-4 sm:mb-0">
                        <p className="mb-1 text-xs md:text-lg text-right md:mt-6">{news.title}</p>
                        <p className="text-xs md:text-sm text-gray-400 mt-3 text-right" dir='rtl'> منذ {formatDistanceToNow(parseISO(news.created_at), { locale: arLocale })} </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MoreDetails;

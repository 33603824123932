import React, { useEffect, useState } from "react";
import about_logo from "../../assets/logo/about-logo.svg";
import call_logo from "../../assets/logo/call-logo.svg";
import cv_logo from "../../assets/logo/cv-logo.svg";
import othersites_logo from "../../assets/logo/othersites-logo.svg";
import logo from "../../assets/logo/logo.png";


import android from "../../assets/logo/android 1.svg";
import apple from "../../assets/logo/apple (1) 1.svg";
import { Link } from "react-router-dom";

function Footer() {

    const [socialMediaLinks, setSocialMediaLinks] = useState([]);
    const [whoAreWeData, setWhoAreWeData] = useState(null);
    const [cvData, setCvData] = useState(null);

    useEffect(() => {
        fetch("https://admin.aliwehbedu.com/v1_0_0-main/get-sections")
            .then((response) => response.json())
            .then((data) => {
                if (data && data.social_media) {
                    setSocialMediaLinks(data.social_media);
                }
            })
            .catch((error) => console.error("Error fetching social media links:", error));

        fetch("https://admin.aliwehbedu.com/v1_0_0-main/get-owner-data")
            .then((response) => response.json())
            .then((data) => {
                if (data && data.success && data.data && data.data.length > 0) {
                    const ownerData = data.data[0];
                    setWhoAreWeData(ownerData.who_are_we);
                    setCvData(ownerData.CV);
                }
            })
            .catch((error) => console.error("Error fetching owner data:", error));
    }, []);

    return (
        <footer className="bg-[#F5F5F5] text-white py-4 flex justify-end text-center bottom-0 w-full h-auto mb-14 lg:mb-0  lg:h-[301px] flex-col items-center">
            <div className=" mx-auto flex justify-center flex-col sm:pt-0 md:pt-0 lg:pt-0 w-full">
                <div className="flex flex-col md:flex-row justify-end items-center md:mr-11 pb-7 text-text-blueNav decoration-4 ">
                    <div className="flex justify-center items-center md:gap-7 mb-4 md:mb-0 sm:mr-20 md:mr-40 gap-3 md:flex-row flex-col ">
                        <div className="flex gap-20 md:gap-6 justify-end ">
                            <Link to="/contact">
                                <div className="flex flex-row justify-center items-center gap-3">
                                    <span className="text-text-blueNav">إتصل بنا</span>
                                    <img src={call_logo} alt="" />
                                </div>
                            </Link>


                            {cvData && (
                                <Link to="/bio">
                                    <div className="flex flex-row justify-center items-center gap-3">
                                        <span className="text-text-blueNav">السيرة الذاتية</span>
                                        <img src={cv_logo} alt="" />
                                    </div>
                                </Link>
                            )}
                        </div>

                        <div className="flex md:mr-0 gap-24 mr-4 md:gap-6 justify-end ">
                            <Link to="/useful-links">
                                <div className="flex flex-row justify-center items-center gap-3">
                                    <span className="text-text-blueNav">روابط مفيدة</span>
                                    <img src={othersites_logo} alt="" />
                                </div>
                            </Link>

                            {whoAreWeData && (
                                <Link to="/about">
                                    <div className="flex flex-row justify-center items-center gap-3">
                                        <span className="text-text-blueNav">من نحن؟</span>
                                        <img src={about_logo} alt="" />
                                    </div>
                                </Link>
                            )}
                        </div>
                    </div>
                    <div className="flex justify-center items-center flex-col">
                        <div>
                            <img src={logo} alt="" className="w-12" />
                        </div>
                        <div className="text-text-blueNav font-montserrat-semibold">
                            Aliwehbedu
                        </div>
                    </div>
                </div>
                <hr className="border-b border-text-blueNav w-[95%] mx-auto " />
                <div className=" flex flex-col lg:flex-row justify-between items-center lg:mt-0 sm:gap-7 pt-7">
                    <div className="flex flex-col items-center gap-4 mb-4 lg:mb-0  lg:ml-12 ">
                        <div>
                            <span className="text-text-blueNav font-montserrat-med">
                                متــــــــــــوفر عـــــــلى أجهزة أبل وأندرويد
                            </span>
                        </div>
                        <div className="flex flex-row gap-3">
                            <a href="app_store_url" target="_blank" rel="noopener noreferrer">
                                <div className="flex flex-row gap-2 py-3 px-6 bg-white rounded-2xl shadow-lg cursor-pointer">
                                    <img src={apple} alt="App Store" />
                                    <span className="text-text-blueNav font-montserrat-bold">App Store</span>
                                </div>
                            </a>
                            <a href="google_play_url" target="_blank" rel="noopener noreferrer">
                                <div className="flex flex-row gap-2 py-3 px-6 bg-white rounded-2xl shadow-2xl cursor-pointer">
                                    <img src={android} alt="Google Play" />
                                    <span className="text-text-blueNav font-montserrat-bold">Google Play</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="flex flex-col items-center lg:items-end gap-8 lg:gap-4">
                        <div className="lg:mr-16">
                            <span className="text-text-blueNav font-montserrat-med text-center lg:text-right " dir="rtl">
                                تابِع بِدقَّة كُلّ ما يتَعلَّق بالمُستَجِدَّات التربويَّة حَولَ
                                التعليم في لبنان.
                            </span>
                        </div>
                        <div className="flex flex-row gap-3 lg:mr-16">
                            {socialMediaLinks.map((link) => (
                                <a key={link.id} href={link.link} target="_blank" rel="noopener noreferrer">
                                    <img src={`https://admin.aliwehbedu.com${link.main_image}`} className="w-9 cursor-pointer" alt={link.id} />
                                </a>
                            ))}

                        </div>
                    </div>
                </div>

                <p className="text-text-blueNav mt-4">&copy; 2024 Aliwehbedu. All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;
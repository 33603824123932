import React, { useState, useEffect } from "react";
import HomeSlider from '../../components/home-slider/HomeSlider'
import ReadMore from "../../components/readMore/ReadMore";
import Countdown from '../../components/countdown/Countdown';
import Section from '../../components/section/section';
import Vote from '../../components/vote-home/Vote';
import { IoCheckbox } from "react-icons/io5";

function Home({data}) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div  className="bg-[#003366] overflow-hidden md:min-h-lvh">
        <HomeSlider/>
        <Countdown/>
        <Section/>
        <div className="bg-[#003366] px-6">
        <Vote/>
        </div>
        <div className="bg-[#003366] px-6 pb-10">
        <ReadMore/>
        </div>
        </div>
        

    )
}

export default Home
import React, { useState, useEffect } from "react";
import axios from "axios";
import live from "../../assets/images/live.png";
import noData from "../../assets/images/nodata.png";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';

let cachedNewsData = null;

function LiveNewsComponent() {
  const [newsItems, setNewsItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const NEWS_INCREMENT = 10;
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        setLoadingMore(true);
        if (cachedNewsData) {
          setNewsItems(cachedNewsData);
          setLoading(false);
        } else {
          const response = await axios.get(
            "https://admin.aliwehbedu.com/v1_0_0-main/get-live-news"
          );
          cachedNewsData = response.data.data;
          setNewsItems(response.data.data);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching news data:", error);
        setLoading(false);
      } finally {
        setLoadingMore(false);
      }
    };

    fetchNews();
  }, []);

  const loadMoreNews = () => {
    setOffset(prevOffset => prevOffset + NEWS_INCREMENT);
  };

  useEffect(() => {
    const fetchMoreNews = async () => {
      try {
        setLoadingMore(true);
        const response = await axios.get(
          `https://admin.aliwehbedu.com/v1_0_0-main/get-live-news?offset=${offset}`
        );
        if (response.data.success) {
          const newNews = response.data.data;
          if (newNews.length < NEWS_INCREMENT) {
            setAllDataLoaded(true);
          }
          setNewsItems(prevNews => [...prevNews, ...newNews]);
        } else {
          console.error('Failed to fetch more news:', response.data.error);
        }
      } catch (error) {
        console.error('Error fetching more news:', error);
      } finally {
        setLoadingMore(false);
      }
    };

    if (offset > 0) {
      fetchMoreNews();
    }
  }, [offset]);

  return (
    <div className="flex flex-col items-center mt-2 mb-10 px-4 sm:px-6 lg:px-8">
      <div className="flex items-center justify-center text-[#003366] text-3xl ">
        <span className="font-montserrat-semibold text-xl md:text-[30px] text-center">
          على مدار الساعة
        </span>
        <img src={live} className="ml-4 md:mb-5 mb-2 w-14 h-14 md:w-24 md:h-24 animate-pulse" />
      </div>

      {loading ? (
        [...Array(4)].map((_, index) => (
          <div
            key={index}
            className="flex items-center justify-between py-6 mt-6 p-4 mb-4 border border-[#003366] rounded-lg shadow-md h-auto w-full sm:w-4/5 transition-transform duration-300"
          >
            <div className="flex-1 mr-4">
              <Skeleton height={30} />
              <Skeleton height={20} width="75%" />
            </div>
            <Skeleton width={50} height={20} />
          </div>
        ))
      ) : newsItems.length > 0 ? (
        newsItems.map((item, index) => (
          <div
            key={index}
            className="flex items-center justify-between py-5 mt-6 p-4 border border-[#003366] rounded-lg shadow-md h-auto w-full sm:w-4/5 hover:transform hover:scale-105 transition-transform duration-300 "
          >
            <div
              className="flex-1 font-montserrat-regular text-[#003366] mr-4  text-right text-sm lg:text-lg xl:text-xl"
              style={{ direction: "rtl" }}
            >
              <div dangerouslySetInnerHTML={{ __html: item.live_news }} />
            </div>
            <div className="bg-[#003366] text-white px-2 py-1 rounded-md text-[10px] lg:text-sm md:text-sm sm:text-md font-semibold flex items-center">
              {new Date(item.created_at).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </div>
          </div>
        ))
      ) : (
        <div className="flex flex-col justify-center items-center w-full h-full">
          <img src={noData} alt="No data" className="max-w-full max-h-full" />
          <p className="text-center text-xl font-montserrat-bold text-[#003366] mt-4">لا توجد بيانات</p>
        </div>
      )}

      {loadingMore && (
        <div className="flex justify-center items-center mt-4 mb-2">
          <Skeleton height={80} width={900} className="rounded-lg" />
        </div>
      )}

      {!loading && !allDataLoaded && (
        <div className="flex justify-center items-center mt-4 mb-2">
          <button
            className="bg-[#003366] text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105 mb-6"
            onClick={loadMoreNews}
          >
            عرض المزيد
          </button>
        </div>
      )}
    </div>
  );
}

export default LiveNewsComponent;
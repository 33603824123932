import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { PiLinkSimpleFill } from "react-icons/pi";
import noData from '../../assets/images/nodata.png';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function UsefulLinks() {
    const [linksData, setLinksData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchLinks = async () => {
            try {
                const response = await axios.get('https://admin.aliwehbedu.com/v1_0_0-main/get-useful-links');
                if (response.data && response.data.success) {
                    setLinksData(response.data.data);
                } else {
                    console.error("Unexpected response data:", response.data);
                }
            } catch (error) {
                console.error("Error fetching links data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchLinks();
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='md:w-[100%] flex flex-col justify-start items-center mb-10 relative md:min-h-lvh'>
            <div className='flex justify-center items-center w-full p-4'>
                <div className='flex mt-4 flex-row justify-center items-center'>
                    <h1 className='font-montserrat-semibold text-text-blueNav mr-3 text-[28px] md:text-4xl' style={{ direction: 'rtl'}}>روابط مفيدة</h1>
                    <PiLinkSimpleFill className="text-text-blueNav text-5xl" />
                </div>
            </div>
            
            {loading ? (
                <div className='grid md:grid-cols-2 mt-10 gap-x-40 gap-y-8 sm:grid-cols-1' style={{ direction: 'rtl'}}>
                    {[...Array(4)].map((_, index) => (
                        <div className='relative hover:transform hover:scale-105 transition-transform duration-300' key={index}>
                            <div className="image-button relative">
                                <div className='md:w-[420px] w-[300px] sm:w-[240px] font-montserrat-regular h-24 mx-auto rounded-lg border border-[#003366] flex justify-center items-center'>
                                    <Skeleton width={200} height={30} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : linksData.length > 0 ? (
                <div className='grid md:grid-cols-2 mt-10 gap-x-40 gap-y-8 sm:grid-cols-1' style={{ direction: 'rtl'}}>
                    {linksData.map((link) => (
                        <div className='relative shadow-lg hover:transform hover:scale-105 transition-transform duration-300' key={link.id}>
                            <a href={link.link} className="image-button relative" target="_blank" rel="noopener noreferrer">
                                <div className='md:w-[420px] w-[300px] sm:w-[240px] font-montserrat-regular h-24 mx-auto rounded-lg border-2 border-[#003366]'>
                                    <h1 className='absolute inset-0 flex justify-center items-center text-[#003366] text-xl md:text-2xl'>{link.title}</h1>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="flex flex-col justify-center items-center w-full h-full">
                    <img src={noData} alt="No data" className="max-w-full max-h-full" />
                    <p className="text-center text-xl font-montserrat-bold text-[#003366] mt-4">لا توجد بيانات</p>
                </div>
            )}
        </div>
    );
}

export default UsefulLinks;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaCircleArrowRight } from "react-icons/fa6";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import noData from '../../assets/images/nodata.png';
import materialsImage from '../../assets/images/materials.png';

function Materials() {
    const location = useLocation();
    const { gradeName } = location.state || {};
    const navigate = useNavigate();
    const id = location.state?.id;
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                console.log(`Fetching courses for grade ID: ${id}`);
                const response = await fetch(
                    `https://admin.aliwehbedu.com/v1_0_0-main/get-grade-courses/?id=${id}`
                );

                if (response.ok) {
                    const data = await response.json();
                    console.log("API Response:", data);
                    if (data.success) {
                        setCourses(data.data);
                    } else {
                        console.error("Error fetching courses:", data);
                    }
                } else {
                    const text = await response.text();
                    console.error("Error response from server:", text);
                }
            } catch (error) {
                console.error("Error fetching courses:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchCourses();
    }, [id]);

    const handleCourseClick = (course) => {
        navigate(`/exams-year/${course.slug}`, {
            state: { materialName: course.course_name, materialId: course.id, slug: course.slug },
        });
    };

    const handleBackClick = () => {
        navigate(-1);
    };

    return (
        <div className="flex flex-col justify-start items-center bg-bg md:min-h-lvh">
            <div className="flex flex-col justify-evenly items-center w-full p-4 text-center text-white font-montserrat-semibold">
                <div className="flex flex-row justify-between items-center w-full">
                    <button
                        onClick={handleBackClick}
                        className="text-white absolute right-5 my-4 rounded-full focus:outline-none"
                    >
                        <FaCircleArrowRight className="text-4xl" />
                    </button>
                    <div className="tab">
                        <ul>
                            <li className="list-item active">
                                <b className="left-curve"></b>
                                <b className="right-curve"></b>
                                <h2 className="text-hoverText px-4 py-1">{gradeName || <Skeleton width={100} />}</h2>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="bg-white img-class w-full rounded-2xl py-16 px-6 text-right space-y-14">
                    {loading ? (
                        <div className="grid gap-y-6 grid-cols-2 gap-4 justify-center" style={{ direction: 'rtl' }}>
                            {[1, 2, 3, 4].map((_, index) => (
                                <div key={index} className="relative lg:w-[450px] md:w-[300px] h-[100px] lg:h-[200px] md:h-[200px] w-full mx-auto hover:transform hover:scale-105
                                     transition-transform duration-300 cursor-pointer rounded-md">
                                    <Skeleton height="100%" className="rounded-md h-[100px] lg:h-[200px] md:h-[200px]" />
                                </div>
                            ))}
                        </div>
                    ) : courses.length > 0 ? (
                        <div className="grid gap-y-6 grid-cols-2 gap-4 justify-center" style={{ direction: "rtl" }}>
                            {courses.map((course) => (
                                <div
                                    key={course.id}
                                    onClick={() => handleCourseClick(course)}
                                    className="relative lg:w-[450px] md:w-[300px] h-[100px] lg:h-[200px] md:h-[200px] w-full mx-auto hover:transform hover:scale-105
                                     transition-transform duration-300 cursor-pointer rounded-md"
                                >
                                    <img
                                        src={
                                            course.main_image
                                                ? `https://admin.aliwehbedu.com${course.main_image}`
                                                : materialsImage
                                        }
                                        className="w-full h-full rounded object-cover"
                                        alt={course.course_name}
                                    />
                                    <div className="absolute inset-0 bg-blueborder opacity-20 rounded"></div>
                                    <h1 className="absolute inset-0 flex justify-center items-center text-center text-white text-md md:text-lg lg:text-2xl">
                                        <span className="bg-[#7e91a4cf] p-2 rounded-md">{course.course_name}</span>
                                    </h1>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="flex flex-col justify-center items-center w-full h-full">
                            <img src={noData} alt="No data" className="max-w-full max-h-full" />
                            <p className="text-center text-xl font-montserrat-bold text-[#003366] mt-4">لا توجد بيانات</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Materials;

import React, { useState, useEffect } from 'react';
import { IoArrowForwardCircleSharp, IoDocumentText } from "react-icons/io5";
import bg_paperwork from "../../assets/images/bg_paperwork.png";
import { Link, useLocation } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import noData from '../../assets/images/nodata.png'; 
import 'react-loading-skeleton/dist/skeleton.css';

function Paperwork() {
    const location = useLocation();
    const { state } = location;
    const { id, type, sectionName } = state; 
    const [linksData, setLinksData] = useState([]);
    const [loading, setLoading] = useState(true); 

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchLinksData = async () => {
            try {
                const response = await fetch(`https://admin.aliwehbedu.com/v1_0_0-main/get-related-section-data?id=${id}&type=${type}`);
                const data = await response.json();
                if (data.success) {
                    setLinksData(data.data.map(section => ({
                        id: section.id,
                        section_id: section.section_id,
                        type: section.type,
                        documentName: section.document_name,
                    })));
                } else {
                    console.error("Failed to fetch links data:", data.message);
                }
            } catch (error) {
                console.error("Error fetching links data:", error);
            } finally {
                setLoading(false); 
            }
        };

        fetchLinksData();
    }, [id, type]);

    return (
        <div className='w-[80%] ml-10 md:ml-0 md:w-[100%] flex flex-col justify-start items-center mb-10 relative md:min-h-lvh'>
            <div className='flex justify-center items-center mt-4 w-full p-4'>
                <div className='flex flex-row justify-center items-center'>
                    <h1 className='font-montserrat-semibold text-text-blueNav mr-3 text-[20px] md:text-4xl' style={{ direction: 'rtl' }}>
                        {loading ? (
                            <Skeleton width={200} height={30} />
                        ) : (
                            'الأوراق المطلوبة'
                        )}
                    </h1>
                    <IoDocumentText className="text-text-blueNav text-5xl" />
                </div>
            </div>
            <div className='relative w-full flex justify-end'>
                <Link to="/">
                    <IoArrowForwardCircleSharp className="absolute hidden md:block text-text-blueNav text-5xl top-[-1.5rem] right-20" />
                </Link>
            </div>
            <div className='grid md:grid-cols-2 mt-10 gap-x-40 gap-y-14 sm:grid-cols-1 sm:px-4' style={{ direction: 'rtl' }}>
                {loading ? (
                    [...Array(4)].map((_, index) => (
                        <div className='relative' key={index}>
                            <button className="image-button relative hover:transform hover:scale-105 transition-transform duration-300">
                                <img src={bg_paperwork} className='w-full md:w-[520px] h-24 mx-auto' alt="" />
                                <h1 className='absolute inset-0 flex justify-center items-center text-white text-xl md:text-2xl'>
                                    <Skeleton width={150} />
                                </h1>
                            </button>
                        </div>
                    ))
                ) : linksData.length > 0 ? (
                    linksData.map(link => (
                        <div className='relative' key={link.id}>
                            <Link to={`/needed-papers-details/${link.section_id}`}>
                                <button className="image-button relative hover:transform hover:scale-105 transition-transform duration-300">
                                    <img src={bg_paperwork} className='w-full md:w-[520px] h-24 mx-auto' alt="" />
                                    <h1 className='absolute inset-0 flex justify-center items-center text-white text-xl md:text-2xl'>
                                        {link.documentName}
                                    </h1>
                                </button>
                            </Link>
                        </div>
                    ))
                ) : (
                    <div className="flex flex-col justify-center items-center w-full h-full">
                        <img src={noData} alt="No data" className="max-w-full max-h-full" />
                        <p className="text-center text-xl font-montserrat-bold text-[#003366] mt-4">لا توجد بيانات</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Paperwork;

import { useState } from "react";
import HeaderLeft from "./HeaderLeft";
import Navbar from "./Navbar";
import SearchBar from "../search/SearchBar";

const Header = () => {
    const [toggle, setToggle] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    const handleSearchResults = (results) => {
        setSearchResults(results);
    };
    return (
        <header className="w-full h-[69px] px-5 flex items-center justify-between bg-bg relative z-50  ">
            <HeaderLeft toggle={toggle} setToggle={setToggle} />
            <div className="hidden xl:block"><SearchBar onSearch={handleSearchResults} /></div>
            <Navbar toggle={toggle} setToggle={setToggle} />
        </header>
    );
};

export default Header;
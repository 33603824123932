import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import "./navbar.css";
import home_logo from "../../assets/logo/home-logo.svg";
import news_logo from "../../assets/logo/news-logo.svg";
import live_logo from "../../assets/logo/live-logo.svg";
import note_logo from "../../assets/logo/note-logo.svg";
import home_logo_active from "../../assets/logo/home-logo-active.svg";
import news_logo_active from "../../assets/logo/news-logo-active.svg";
import live_logo_active from "../../assets/logo/live-logo-active.svg";
import note_logo_active from "../../assets/logo/note-logo-active.svg";

const Navbar = ({ toggle, setToggle }) => {
    
    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location.pathname);

    useEffect(() => {
        setActiveLink(location.pathname);
    }, [location.pathname]);

    const linkClasses = (path) =>
        `list-item-hov ${activeLink === path ? "active" : ""}`;

    const linkStyles = (path) => ({
        color: activeLink === path ? "#003366" : "#AAAAAA",
    });

    const getImage = (path) => {
        switch (path) {
            case "/":
                return activeLink === path ? home_logo_active : home_logo;
            case "/my-diary":
                return activeLink === path ? note_logo_active : note_logo;
            case "/live-news":
                return activeLink === path ? live_logo_active : live_logo;
            case "/news":
                return activeLink === path ? news_logo_active : news_logo;
            default:
                return home_logo;
        }
    };

    return (
        <div
            className={` navbar lg:flex ${toggle ? "show" : "hide"}  ${toggle ? "flex" : "hidden"} absolute lg:relative left-0 top-[67px] lg:top-0 w-full lg:w-auto bg-bg-borderblue lg:bg-transparent flex justify-end transform transition-transform duration-700 ease-in-out lg:transform-none mr-10 `}
        >
            <ul className="flex flex-col-reverse lg:flex-row items-start lg:items-center justify-end lg:gap-9 lg:pl-0">
                <li className={linkClasses("/my-diary")}>
                    <b className="left-curve"></b>
                    <b className="right-curve"></b>
                    <Link
                        onClick={() => setToggle(false)}
                        to="/my-diary"
                        className="nav-link flex flex-row lg:gap-4 justify-center items-center"
                    >

                        <h2 className="text-[#AAAAAA]" style={linkStyles("/my-diary")}>
                            مذكرات 
                        </h2>


                        <img src={getImage("/my-diary")} alt="my-diary" />

                    </Link>
                </li>
                <li className={linkClasses("/news")}>
                    <b className="left-curve"></b>
                    <b className="right-curve"></b>
                    <Link
                        onClick={() => setToggle(false)}
                        to="/news"
                        className="nav-link flex flex-row lg:gap-4 justify-center items-center"
                    >

                        <h2 className="text-[#AAAAAA] pl-3 " style={linkStyles("/news")}>
                            الأخبار
                        </h2>

                        <img src={getImage("/news")} alt="news" />
                    </Link>
                </li>
                <li className={linkClasses("/live-news")}>
                    <b className="left-curve"></b>
                    <b className="right-curve "></b>
                    <Link
                        onClick={() => setToggle(false)}
                        to="/live-news"
                        className="nav-link flex flex-row  justify-center items-center"
                    >

                        <h2 className="text-[#AAAAAA] pl-3 lg:pr-3" style={linkStyles("/live-news")}>
                            مباشر
                        </h2>

                        <img src={getImage("/live-news")} alt="My Courses" />
                    </Link>
                </li>
                <li className={linkClasses("/")}>
                    <b className="left-curve"></b>
                    <b className="right-curve"></b>
                    <Link
                        onClick={() => setToggle(false)}
                        to="/"
                        className="nav-link flex flex-row lg:gap-4 justify-center items-center"
                    >

                        <h2 className="text-[#AAAAAA]" style={linkStyles("/")}>
                            الرئيسية
                        </h2>

                        <img src={getImage("/")} alt="Home" />
                    </Link>
                    
                </li>
            </ul>
        </div>
    );
};

export default Navbar;

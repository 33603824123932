import React, { useState, useEffect } from 'react';

const Voting = ({ sectionId, userUuid, offset }) => {
  const [votingData, setVotingData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://admin.aliwehbedu.com/v1_0_0-main/get-voting-data?section_id=${sectionId}&user_uuid=${userUuid}&offset=${offset}`);
        const data = await response.json();
        if (data.success) {
          setVotingData(data.data);
        }
      } catch (error) {
        console.error('Error fetching voting data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [sectionId, userUuid, offset]);

  const VotingCardSkeleton = () => (
    <div className="bg-white p-2 lg:w-[400px] md:w-[400px] sm:w-full m border rounded-lg shadow-md text-blueborder font-montserrat-bold transform transition-transform duration-300 hover:scale-105">
      <h3 className="text-lg mb-4 text-center animate-pulse">Loading...</h3>
      <div className="space-y-6">
        {[...Array(3)].map((_, index) => (
          <div key={index} className="flex flex-col justify-between items-start">
            <div className="flex justify-between w-full mb-1">
              <span className="bg-gray-200 h-4 w-1/3 rounded-lg animate-pulse"></span>
              <span className="bg-gray-200 h-4 w-1/3 rounded-lg animate-pulse"></span>
            </div>
            <div className="w-full bg-gray-200 rounded-lg h-4 relative">
              <div className="bg-gray-200 h-4 rounded-lg absolute top-0 right-0 animate-pulse"></div>
            </div>
          </div>
        ))}
      </div>
      <div className="text-left text-gray-400 text-sm mt-4">
        <span className="bg-gray-200 h-4 w-1/4 rounded-lg animate-pulse"></span>
      </div>
    </div>
  );

  return (
    <div className="container mx-auto my-8 p-2">
      <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-6  justify-items-center">
        {loading ? (
          <React.Fragment>
            <VotingCardSkeleton />
            <VotingCardSkeleton />
            <VotingCardSkeleton />
          </React.Fragment>
        ) : (
          votingData.map((data, index) => (
            <VotingCard
              key={index}
              question={data.question}
              votes={data.options}
              endingAt={data.ending_at}
            />
          ))
        )}
      </div>
    </div>
  );
};

const VotingCard = ({ question, votes, endingAt }) => {
  const formatTime = (datetimeString) => {
    const date = new Date(datetimeString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  return (
    <div className="bg-white p-2 lg:w-[400px] md:w-[400px] sm:w-full m border rounded-lg shadow-md text-blueborder font-montserrat-bold transform transition-transform duration-300 hover:scale-105">
      <h3 className="text-lg mb-4 text-center">{question}</h3>
      <div className="space-y-6">
        {votes.map((vote, index) => (
          <div key={index} className="flex flex-col justify-between items-start">
            <div className="flex justify-between w-full mb-1">
              <span>{vote.percent}% ({vote.count})</span>
              <span>{vote.text}</span>
            </div>
            <div className="w-full bg-gray-200 rounded-lg h-4 relative">
              <div
                className="bg-blueborder h-4 rounded-lg absolute top-0 right-0"
                style={{ width: `${vote.percent}%` }}
              ></div>
            </div>
          </div>
        ))}
      </div>
      <div className="text-left text-gray-400 text-sm mt-4">
        <span>{formatTime(endingAt)}</span>
      </div>
    </div>

  );
};

export default Voting;

import React from 'react'
import Notes from '../../components/notes/Notes'

function MyDiary() {
    return (
        <div className='md:min-h-lvh'>
            <Notes />
        </div>
    )
}

export default MyDiary
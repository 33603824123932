import React, { useState, useEffect } from "react";
import { ThreeCircles } from "react-loader-spinner";
import { formatDistanceToNow, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import noDataImage from "../../assets/images/nodata.png";
import arLocale from "date-fns/locale/ar-SA";

const News = () => {
  const [newsItems, setNewsItems] = useState([]);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const navigate = useNavigate();
  const NEWS_INCREMENT = 20; // Define how many news items to fetch at a time

  const fetchNews = async (offset) => {
    try {
      setIsLoadingMore(true);
      const response = await fetch(
        `https://admin.aliwehbedu.com/v1_0_0-main/get-news/?offset=${offset}&limit=${NEWS_INCREMENT}`
      );
      const data = await response.json();
      if (data.success) {
        if (data.data.length === 0) {
          setAllDataLoaded(true);
        } else {
          setNewsItems((prevNewsItems) => [...prevNewsItems, ...data.data]);
          if (data.data.length < NEWS_INCREMENT) {
            setAllDataLoaded(true);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching news:", error);
    } finally {
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  };

  useEffect(() => {
    fetchNews(offset);
  }, [offset]);

  const loadMoreNews = () => {
    setOffset((prevOffset) => prevOffset + NEWS_INCREMENT);
  };

  const handleClick = (id) => {
    navigate(`/news-details/${id}`);
  };

  return (
    <div>
      <div className="w-full font-montserrat-semibold text-right">
        <div className="tab">
          <ul className="flex justify-end">
            <li className="list-item active relative mr-9">
              <b className="left-curve absolute right-full"></b>
              <b className="right-curve absolute left-full"></b>
              <h2 className="text-hoverText">آخر الأخبار</h2>
            </li>
          </ul>
        </div>
      </div>

      <div
        className="border-solid border-4 bg-white border-white rounded-lg text-[#003366]"
        dir="rtl"
      >
        {isLoading ? (
          <div className="grid grid-cols-1 font-montserrat-semibold lg:grid-cols-2 gap-10 p-4">
            {[...Array(NEWS_INCREMENT)].map((_, index) => (
              <div
                key={index}
                className="flex flex-col items-center border-b border-gray-400 pb-4 mb-4 transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg cursor-pointer"
              >
                <Skeleton height={150} width={"100%"} />
                <Skeleton height={20} width={200} className="mt-2" />
                <Skeleton height={15} width={100} className="mt-1" />
              </div>
            ))}
          </div>
        ) : (
          <div className="font-montserrat-semibold p-4">
            {newsItems.length === 0 ? (
              <div className="flex justify-center items-center">
                <img
                  src={noDataImage}
                  alt="No data"
                  className="max-w-full max-h-full"
                />
              </div>
            ) : (
              <div className="grid grid-cols-1 lg:grid-cols-2 md:gap-10 gap-4">
                {newsItems.map((news, index) => (
                  <div
                    key={news.id}
                    className="flex flex-col items-center border-b border-gray-400 pb-4 mb-4 transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg cursor-pointer"
                    onClick={() => handleClick(news.id)}
                  >
                    <div className="flex h-full mr-2 mt-2 md:h-auto lg:relative flex-row sm:flex-row items-center justify-start w-full">
                      <div className="lg:relative w-32 h-16 image-container md:ml-10 sm:w-40 md:h-32 sm:h-30 rounded-md mb-4 sm:mb-0 overflow-hidden md:w-[180px]">
                        <img
                          src={`https://admin.aliwehbedu.com${news.main_image}`}
                          alt={`News ${index + 1}`}
                          className="lg:absolute h-full object-cover w-full md:w-[200px]"
                        />
                      </div>
                      <div className="lg:relative items-center md:w-[410px] w-full h-16 pr-4 md:ml-10 md:h-32 sm:h-30 mb-4 sm:mb-0">
                        <p className="mb-1 text-xs md:text-lg lg:text-lg text-right md:mt-6 ">
                          {news.title}
                        </p>
                        <p
                          className="text-xs md:text-sm text-gray-400 mt-3 text-right"
                          dir="rtl"
                        >
                          منذ{" "}
                          {formatDistanceToNow(parseISO(news.created_at), {
                            locale: arLocale,
                          })}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        <div className="flex justify-center items-center">
          {isLoadingMore ? (
            <div className="flex justify-end items-center mt-4 mb-2">
              <ThreeCircles
                visible={true}
                height={100}
                width={100}
                color="#003366"
                ariaLabel="three-circles-loading"
                wrapperStyle={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
                wrapperClass=""
              />
            </div>
          ) : (
            !isLoading && !allDataLoaded && (
              <button
                className="mt-4 bg-blueborder text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105 mb-6"
                onClick={loadMoreNews}
              >
                عرض المزيد
              </button>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default News;

import React, { useState, useEffect } from "react";
import axios from "axios";
import noData from '../../assets/images/nodata.png';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// Global variable to store cached CV data
let cachedCvData = '';

function Bio() {
  const [cvData, setCvData] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  useEffect(() => {
    const fetchCvData = async () => {
      try {
        if (cachedCvData) {
          // Use cached CV data if available
          setCvData(cachedCvData);
        } else {
          const response = await axios.get("https://admin.aliwehbedu.com/v1_0_0-main/get-owner-data");
          const ownerData = response.data.data[0];
          cachedCvData = ownerData.CV; // Cache the CV data
          setCvData(ownerData.CV);
        }
      } catch (error) {
        console.error("Error fetching CV data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCvData();
  }, []);

  return (
    <div className="flex flex-col justify-start items-center mb-10 md:min-h-lvh">
      <div className="flex flex-col justify-evenly items-center w-full p-4 text-center text-white font-montserrat-semibold">
        <div className="flex flex-row justify-between items-center w-full font-montserrat-semibold">
          <div className="tab-white">
            <ul>
              <li className="list-item active">
                <b className="left-curve"></b>
                <b className="right-curve"></b>
                <h2 className="text-white">السيرة الذاتية</h2>
              </li>
            </ul>
          </div>
        </div>
        <div className="bg-white border-4 w-full text-[#003366] border-[#003366] lg:w-[900px] rounded-2xl py-6 px-6 text-right space-y-14">
          {loading ? (
            <Skeleton count={5} height={20} />
          ) : cvData ? (
            <div className="font-montserrat-light" style={{ lineHeight: "2", direction:'rtl' }}>
              <div dangerouslySetInnerHTML={{ __html: cvData }} />
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center w-full h-full">
                    <img src={noData} alt="No data" className="max-w-full max-h-full" />
                    <p className="text-center text-xl font-montserrat-bold text-[#003366] mt-4">لا توجد بيانات</p>
                  </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Bio;

import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo/logo.png";
import RightSide from "./RightSide";
import "./rightSide.css"; 

const HeaderLeft = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(prevState => !prevState);
    };

    const handleClickOutside = (event) => {
        if (isOpen && sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1024) {
                setIsOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const sidebarRef = useRef(null);




    return (
        <div className="flex justify-between items-center w-full">
            <div>
                <Link to="/" className="flex items-center text-3xl font-bold text-white">
                    <div>
                        <img src={logo} alt="Logo" className="w-12 h-12" />
                    </div>
                    <div>
                        <span className="ml-4 text-white text-2xl font-montserrat-semibold">Aliwehbedu</span>
                    </div>
                </Link>
            </div>
            <div onClick={handleToggle} className="ml-auto text-4xl text-white cursor-pointer lg:hidden">
                {isOpen ? (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                )}
            </div>
            


            {isOpen && <RightSide ref={sidebarRef} setToggle={setIsOpen} />}
        </div>
    );
};

export default HeaderLeft;
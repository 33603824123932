import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { IoArrowForwardCircleSharp } from "react-icons/io5";
import { Link, useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function NeededPapersDetails() {
    const { id } = useParams();
 
    const [documentData, setDocumentData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchDocumentData = async () => {
            try {
                const response = await axios.get(`https://admin.aliwehbedu.com/v1_0_0-main/get-required-documents?id=${id}`);

                if (response.data && response.data.success) {
                    setDocumentData(response.data.data);
                } else {
                    console.error("Unexpected response data:", response.data);
                }
            } catch (error) {
                console.error("Error fetching document data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchDocumentData();
    }, [id]); 

    const openPdfInNewTab = (pdfUrl) => {
        window.open(pdfUrl, '_blank');
    };

    return (
        <div className='flex flex-col justify-center items-center mb-10 md:min-h-lvh'>
            <div className='flex md:flex-row justify-end items-center w-full p-4'>
            {documentData && documentData.length > 0 && (
                <h1 className='font-montserrat-semibold text-text-blueNav text-xl md:text-4xl' style={{ direction: 'rtl' }}>
                    {documentData[0].document_name}
                </h1>
            )}

                <Link to="/">
                    <IoArrowForwardCircleSharp className="text-text-blueNav ml-4 md:ml-10 text-5xl top-[-1.5rem]" />
                </Link>
            </div>

            <div className='mt-4 w-[80%]'>
                {loading ? (
                    <div>
                        <Skeleton height={300} count={3} />
                    </div>
                ) : (
                    documentData && documentData.map(doc => (
                        <div key={doc.id}>
                            {doc.attachments.length > 0 && (
                                <div className="flex flex-col justify-center items-center">
                                    <button 
                                        onClick={() => openPdfInNewTab(`https://admin.aliwehbedu.com${doc.attachments[0].file_path}`)}
                                        className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
                                    >
                                        Open PDF in New Tab
                                    </button>
                                    <div style={{ width: '100%' }}>
                                        <ul>
                                            {doc.attachments.map((attachment, index) => (
                                                <li key={index} className="mt-2">
                                                    <iframe 
                                                        src={`https://admin.aliwehbedu.com${attachment.file_path}`} 
                                                        width="100%" 
                                                        height="600px" 
                                                        title={attachment.file_name}
                                                        className="md:h-[1200px]" 
                                                    ></iframe>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}

export default NeededPapersDetails;

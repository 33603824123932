import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { FaCircleArrowRight } from "react-icons/fa6";

const NotesDetails = () => {
  const location = useLocation();
  const { note } = location.state || {};
  const navigate = useNavigate();
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(true); 

  useEffect(() => {
    if (!note) {
      navigate('/my-diary');
    } else if (note.attachments && note.attachments.length > 0) {
      const firstPdf = note.attachments.find(attachment => attachment.file_path.toLowerCase().endsWith('.pdf'));
      if (firstPdf) {
        const pdfUrl = `https://admin.aliwehbedu.com${firstPdf.file_path}`;
        const cachedPdfUrl = sessionStorage.getItem('cachedPdfUrl');

        if (cachedPdfUrl && cachedPdfUrl === pdfUrl) {
          setSelectedPdf(cachedPdfUrl);
        } else {
          setSelectedPdf(pdfUrl);
        }
      }
    }
  }, [note, navigate]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleViewPdfClick = (pdfUrl) => {
    window.open(pdfUrl, '_blank');
  };

  
  const handlePdfLoad = () => {
    setLoadingPdf(false); 
    sessionStorage.setItem('cachedPdfUrl', selectedPdf); 
  };

  return (
    <div className="flex flex-col">
      <div className="relative bg-text-blueNav w-full flex justify-center" style={{ direction: 'rtl' }}>
        <button onClick={handleBackClick} className="absolute top-10 right-8 text-white">
          <FaCircleArrowRight className="text-3xl" />
        </button>
        <div className="relative bg-white flex flex-col items-start border-solid border-2 border-white rounded-lg w-5/6 p-8 text-right mt-28 mb-8">
          {note && note.main_image && (
            <img
              className="mb-8 -mt-32 rounded-xl self-center"
              width="400px"
              height="400px"
              src={`https://admin.aliwehbedu.com${note.main_image}`}
              alt={note.title}
            />
          )}
          <h1 className="text-xl text-blueborder font-montserrat-bold mb-2">
            {note ? note.title : <Skeleton />}
          </h1>
  
          {note ? (
            note.description ? (
              <p className="text-blueborder font-montserrat-semibold text-sm" style={{ lineHeight: '2.5' }}>
                {note.description}
              </p>
            ) : (
              <Skeleton height={40} width={'80%'} />
            )
          ) : null}
  
          <div className="bg-white overflow-hidden font-montserrat-regular w-full mt-2">
            <div className="flex flex-col gap-2 items-end">
              <div className="mb-4 w-full" dangerouslySetInnerHTML={{ __html: note?.content || '<div><Skeleton count={5} /></div>' }}></div>
              {note && note.attachments && note.attachments.map((attachment, index) => (
                <div key={index} className="w-full">
                  {attachment.file_path.toLowerCase().endsWith('.pdf') && (
                    <div className="flex justify-start gap-4">
                      <button
                        onClick={() => handleViewPdfClick(`https://admin.aliwehbedu.com${attachment.file_path}`)}
                        className="text-white bg-blue-500 hover:bg-blue-600 py-2 px-4 rounded-lg focus:outline-none"
                      >
                        View PDF in New Tab
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
            {selectedPdf && (
              <div className="mt-4 w-full">
                {isSmallScreen ? (
                  <>
                    {loadingPdf && (
                      <div className="flex justify-center items-center w-full h-full mt-20">
                        <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 ml-4 border-blue-500"></div>
                        <span className="text-blue-500 font-montserrat-semibold text-lg ml-4">يرجى الانتظار...</span>
                      </div>
                    )}
                    <iframe
                      src={`https://docs.google.com/viewer?url=${selectedPdf}&embedded=true`}
                      width="100%"
                      height="500px"
                      style={{ border: 'none' }}
                      title="PDF Viewer"
                      onLoad={handlePdfLoad} 
                    />
                  </>
                ) : (
                  <iframe
                    src={selectedPdf}
                    width="100%"
                    height="1100px"
                    style={{ border: 'none' }}
                    title="PDF Viewer"
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotesDetails;

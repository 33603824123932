import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import contactImage from "../../assets/images/contact image.png";

function Contact() {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    message: ""
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { id, value } = e.target;
    const onlyArabicAndEnglishLettersRegex = /^[\u0621-\u064Aa-zA-Z\s]*$/;

    if (id === "fullName" && !onlyArabicAndEnglishLettersRegex.test(value)) {
      toast.error("الإسم الكامل يجب أن يحتوي على حروف عربية وإنجليزية فقط.", { style: { textAlign: "right", direction: "rtl" } });
      return;
    }

    if (id === "phone" && !/^\d+$/.test(value)) {
      toast.error("الهاتف يجب أن يحتوي على أرقام فقط.", { style: { textAlign: "right", direction: "rtl" } });
      return;
    }
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const missingFields = [];

    if (!formData.fullName) {
      missingFields.push("الإسم الكامل");
    }
    if (!formData.email) {
      missingFields.push("البريد الإلكتروني");
    }
    if (!formData.phone) {
      missingFields.push("الهاتف");
    }
    if (!formData.message) {
      missingFields.push("الرسالة");
    }

    if (missingFields.length > 0) {
      const fieldsString = missingFields.join(", ");
      toast.error(`الرجاء ملء الحقول التالية: ${fieldsString}`, {
        style: {
          textAlign: "right",
          direction: "rtl"
        }
      });
      return;
    }

    try {
      const response = await axios.post('https://admin.aliwehbedu.com/v1_0_0-main/create-contact', {
        full_name: formData.fullName,
        email: formData.email,
        phone_number: formData.phone,
        message: formData.message
      });

      if (response.data.success) {
        Swal.fire({
          icon: 'success',
          title: '!تم حفظ بيانات الاتصال بنجاح',
          text: 'سوف يتم تحويلك تلقائيآ الى الصفحة الرئيسية',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          willOpen: () => {
            const swalTimerProgressBar = Swal.getTimerProgressBar();
            if (swalTimerProgressBar) {
              swalTimerProgressBar.style.backgroundColor = '#003366';
            }
          },
          didClose: () => {
            navigate('/');
          }
        });

        setFormData({
          fullName: "",
          email: "",
          phone: "",
          message: ""
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'فشل في حفظ بيانات الاتصال',
          text: 'يرجى المحاولة مرة أخرى.',
          confirmButtonText: 'موافق',
          showConfirmButton: true,
          timer: 3000,
          timerProgressBar: true,
          willOpen: () => {
            const swalTimerProgressBar = Swal.getTimerProgressBar();
            if (swalTimerProgressBar) {
              swalTimerProgressBar.style.backgroundColor = '#ff0000';
            }
          }
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'حدث خطأ',
        text: 'يرجى المحاولة مرة أخرى.',
        confirmButtonText: 'موافق',
        showConfirmButton: true,
        timer: 3000,
        timerProgressBar: true,
        willOpen: () => {
          const swalTimerProgressBar = Swal.getTimerProgressBar();
          if (swalTimerProgressBar) {
            swalTimerProgressBar.style.backgroundColor = '#ff0000';
          }
        }
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="border-solid border-2 border-blueborder rounded-lg flex flex-row justify-end my-10 p-6 w-11/12 lg:w-2/3 md:w-2/3 mx-auto md:min-h-lvh">
        <div className="hidden lg:block mt-20 lg:left-0 lg:transform lg:-translate-x-1/3">
          <img src={contactImage} alt="contact image" />
        </div>

        <form className="flex flex-col items-end text-blueborder w-full max-w-lg" onSubmit={handleSubmit}>
          <h1 className="block text-lg font-semibold mb-2">+961 70 123 456 :إتصل بنا</h1>
          <h1 className="block text-lg font-semibold">:تواصل معنا عبر رسالة</h1>

          <div className="w-full mt-4">
            <label htmlFor="fullName" className="block text-lg font-semibold text-right">
              الإسم الكامل
            </label>
            <input
              id="fullName"
              className="border border-gray-300 p-2 my-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-right placeholder-custom-blue w-full"
              type="text"
              placeholder="الإسم الكامل"
              value={formData.fullName}
              onChange={handleChange}
              autocomplete="off"
            />

            <label htmlFor="email" className="block text-lg font-semibold text-right">
              البريد الإلكتروني
            </label>
            <input
              id="email"
              className="border border-gray-300 p-2 my-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-right placeholder-custom-blue w-full"
              type="email"
              placeholder="البريد الإلكتروني"
              value={formData.email}
              onChange={handleChange}
              autocomplete="off"
            />

            <label htmlFor="phone" className="block text-lg font-semibold text-right">
              الهاتف
            </label>
            <input
              id="phone"
              className="border border-gray-300 p-2 my-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-right placeholder-custom-blue w-full"
              type="text"
              placeholder="الهاتف"
              value={formData.phone}
              onChange={handleChange}
              autocomplete="off"
            />

            <label htmlFor="message" className="block text-lg font-semibold text-right">
              الرسالة
            </label>
            <textarea
              id="message"
              className="border border-gray-300 p-2 my-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-right placeholder-custom-blue w-full"
              placeholder="الرسالة"
              value={formData.message}
              onChange={handleChange}
              autocomplete="off"
            />

            <button className="bg-blueborder text-white font-semibold py-2 px-4 rounded mt-4 w-full" type="submit">
              أرسل
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default Contact;

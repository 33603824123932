import React, { useEffect, useState } from 'react';

const SocialMediaLinks = () => {
  const [socialMediaLinks, setSocialMediaLinks] = useState([]);

  useEffect(() => {
    fetch("https://admin.aliwehbedu.com/v1_0_0-main/get-sections")
      .then((response) => response.json())
      .then((data) => {
        if (data && data.social_media) {
          setSocialMediaLinks(data.social_media);
        }
      })
      .catch((error) => console.error("Error fetching social media links:", error));
  }, []);

  return (
    <div className="flex flex-row gap-3 mt-4">
      {socialMediaLinks.map((link) => (
        <a key={link.id} href={link.link} target="_blank" rel="noopener noreferrer">
          <img src={`https://admin.aliwehbedu.com${link.main_image}`} className="w-9 cursor-pointer" alt={link.id} />
        </a>
      ))}
    </div>
  );
};

export default SocialMediaLinks;

import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom'; 
import './NewsSlider.css'; // Assuming you have a CSS file for additional styles

// Global variable to store cached slider items
let cachedSliderItems = [];

function NewsSlider({ selectedCategory }) {
  const [sliderItems, setSliderItems] = useState(cachedSliderItems);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSliderItems = async () => {
      try {
        setLoading(true);
        let response;

        if (selectedCategory === "أبرز العناوين" || selectedCategory === null) {
          response = await axios.get('https://admin.aliwehbedu.com/v1_0_0-main/get-news', {
            params: {
              limit: 3,
            },
          });
        } else {
          response = await axios.get('https://admin.aliwehbedu.com/v1_0_0-main/get-news', {
            params: {
              category_id: selectedCategory,
              limit: 3,
            },
          });
        }

        const sortedData = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setSliderItems(sortedData.slice(0, 3));
        cachedSliderItems = sortedData.slice(0, 3); // Cache slider items
      } catch (error) {
        console.error('Error fetching slider items:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSliderItems();
  }, [selectedCategory]);

  const handleClick = (id, slug) => {
    navigate(`/news-details/${id}`, { state: { id } });
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    customPaging: (i) => (
      <div className="custom-dot"></div>
    ),
    appendDots: (dots) => (
      <div className="custom-dots hidden lg:block"> 
        <ul> {dots} </ul>
      </div>
    ),
  };

  // Determine Skeleton height based on screen size
  const skeletonHeight = window.innerWidth >= 1024 ? 400 : 200;

  // Key prop for Slider component to force re-render on state change
  const sliderKey = sliderItems.length; // Ensures re-render when sliderItems change

  return (
    <div className="news-slider-container overflow-hidden relative">
      <Slider key={sliderKey} {...settings}>
        {loading ? (
          [1, 2, 3].map((index) => (
            <div key={index} className="flex flex-col items-center relative w-full h-full">
              <Skeleton height={skeletonHeight} width="100%" />
            </div>
          ))
        ) : (
          sliderItems.map((item) => (
            <div 
              key={item.id} 
              className="flex flex-col items-center relative w-full cursor-pointer md:h-full lg:h-full h-[200px]"
              onClick={() => handleClick(item.id, item.slug)}
            >
              <img
                src={`https://admin.aliwehbedu.com${item.main_image}`}
                alt={item.title}
                className="w-full h-[200px] lg:h-[400px] md:h-[400px] object-cover"
                style={{ borderRadius: 0 }}
              />
              <div className="absolute flex justify-center items-center bottom-0 w-full bg-opacity-90 bg-red-500 py-3 px-6">
                <p className="font-montserrat-semibold text-sm md:text-lg lg:text-2xl text-center text-white">
                  {item.title}
                </p>
              </div>
            </div>
          ))
        )}
      </Slider>
    </div>
  );
}

export default NewsSlider;
